<template>
  <fullscreen
    v-if="camera"
    :fullscreen.sync="fullscreen"
    class="live-video"
    :class="{
      offline: !camera.status || !camera.status.online,
      detectionArea: !!detectionArea,
      maximize: maximize,
      singleview: !isMultiview,
    }"
  >
    <v-toolbar
      v-if="!detectionArea"
      flat
      :height="fullscreen || !isMultiview ? 36 : 22"
      class="camera-toolbar top"
      :class="{ titleFullscreen: maximize, displayNone: cursorHide }"
    >
      <v-toolbar-title
        class="mr-1"
        :class="{ 'font-size-1rem': !fullscreen && isMultiview }"
        >{{ camera.name }}
      </v-toolbar-title>
      <v-icon
        v-if="camera.status && camera.status.recordingOnCloud"
        size="11"
        color="red"
        >fa-circle</v-icon
      >
      <v-spacer></v-spacer>
      <span
        v-if="
          (camera.status && camera.status.connectionType === 'Wifi') ||
          (camera.wifiStatus && camera.wifiStatus.status === 'connected')
        "
        >Wi-Fi</span
      >
      <span v-if="camera.status && camera.status.connectionType === 'Mobile'">
        <v-img class="mr-3 mobile-network" :src="mobileNetworkImage()"></v-img>
      </span>
      <v-img
        v-if="camera.wifiStatus && camera.wifiStatus.strength"
        :src="wifiImages[camera.wifiStatus.strength]"
        width="20"
        max-width="20"
        height="24"
        max-height="24"
        aspect-ratio="1"
        contain
        class="ml-2"
      ></v-img>
      <v-btn
        v-if="!fullscreen"
        icon
        color="transparent"
        class="closeButton"
        @click="removeVideo()"
      >
        <v-icon color="white" size="20">fas fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <div @click.shift="openTool()" class="video-window">
      <div
        class="video-loading"
        v-if="
          !offline &&
          (playState === 'loading' || isBuffering) &&
          type != 'mjpeg' &&
          !cameraDisabled &&
          !useLowLatencyPlayer &&
          !supportAccessEnabled
        "
      >
        <een-spinner
          :color="'white'"
          :size="60"
          :isloading="true"
          :absolute="false"
        ></een-spinner>
        <h3 class="white--text text-center mt-3">{{ $t(stateMessage) }}</h3>
      </div>

      <een-snack-bar
        v-if="
          !offline && !cameraDisabled && useLowLatencyPlayer && openDebugTools
        "
        :showing="!!lowbandwidthMessageLLLP"
        :text="$t(lowbandwidthMessageLLLP)"
        :color="connectivityIssuesMsg.color"
        :timeout="connectivityIssuesMsg.timeout"
        :showCloseBtn="false"
        :smallerText="true"
        class="connectivity-issues-msg d-flex flex-column justify-start align-end"
      ></een-snack-bar>

      <div
        class="video-loading"
        v-if="!offline && statusLLLP && !cameraDisabled && useLowLatencyPlayer"
      >
        <een-spinner
          :color="'white'"
          :size="60"
          :isloading="true"
          :absolute="false"
        ></een-spinner>
        <h3 class="white--text text-center mt-3">{{ $t(statusLLLP) }}</h3>
      </div>

      <div
        class="video-failing d-flex flex-column justify-center align-center text-center mx-auto"
        v-if="!offline && failingLLLP && !cameraDisabled && useLowLatencyPlayer"
      >
        <div class="icon">
          <v-icon color="white" size="50px">fas fa-video-slash</v-icon>
        </div>
        <h3 class="mt-5 white--text headline">{{ $t(failingLLLP) }}</h3>
      </div>

      <div v-if="!cameraDisabled && online" class="video-window">
        <transition name="fade">
          <template>
            <button
              class="play-pause"
              v-show="stateMessage !== 'Connecting' && !offline && !isPlaying"
              @click="togglePlay"
            >
              <svg
                id="play-svg-1"
                ref="playSvg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
              >
                <path ref="path" d="M11,8 L26,16 11,24 11,8">
                  <animate
                    ref="animate"
                    attributeName="d"
                    fill="freeze"
                    dur="0.1s"
                    calc-mode="spline"
                    keySplines="0.19 1 0.22 1"
                  />
                </path>
              </svg>
            </button>
          </template>
        </transition>
        <v-img
          v-if="online && mjpegUrl && type === 'mjpeg' && !cameraDisabled"
          max-height="100%"
          height="100%"
          :key="mjpegKey"
          :src="mjpegView ? mjpegUrl : ''"
          :aspect-ratio="16 / 9"
          contain
        ></v-img>
        <video
          v-if="!useLowLatencyPlayer"
          @play="videoPlayEvent()"
          @pause="videoPauseEvent()"
          v-show="online && type === 'hls' && !supportsDewarping"
          :key="camera.cameraId"
          ref="hlsPlayer"
          id="livePlayer"
          class="playerLive"
          muted="muted"
        ></video>

        <low-latency-live-player
          v-if="
            useLowLatencyPlayer &&
            type !== 'mjpeg' &&
            camera.footageSettings &&
            audioCodec
          "
          :key="multiPartStreamUrl"
          ref="lowLatencyPlayer"
          :url="multiPartStreamUrl"
          :resolution="resolution"
          :autoplay="true"
          :maxBuffer="selectedBuffer"
          :framerate="framerate"
          :cameraId="camera.cameraId"
          :audioVolume="audioVolume / 10 - 1"
          :audioMuted="audioMuted"
          :audioCodec="audioCodec"
          :debugToolsOpen="openDebugTools"
          :useDewarp="supportsDewarping"
          @play="videoPlayEvent()"
          @pause="videoPauseEvent()"
          @bandwidthUpdate="setBandwidth"
          @latencyUpdate="setLatency"
          @calculatedBufferLengthUpdate="
            (value) => (debugData.currentBufferLength = value)
          "
          @emit-dropped-frame="
            (value) =>
              isNaN(value)
                ? debugData.droppedFrames++
                : (debugData.droppedFrames = value)
          "
          @emit-queue-sizes="setQueueSizes"
          @emit-timestamplllp-in-miliseconds="
            (timestamp) => (timeStampLllpInMs = timestamp)
          "
          @emit-lllp-status-message="setStatusLLLP"
          @emit-lllp-failing-message="setFailing"
          @emit-lllp-lowbandwidth-message="setLowbandwidth"
          @emit-audio-detected="showAudioControls = true"
          @emit-audio-buffer-size="
            (value) => (debugData.audioBufferSize = value)
          "
          @emit-mute-audio="audioMuted = true"
        />

        <div
          ref="dewarpingContainer"
          class="video-movement-controls-container"
          v-if="supportsDewarping"
        >
          <video-movement-controls
            v-if="immervisionPlayer"
            class="video-movement-arrow-controls"
            @arrowPress="handleRotationInput"
          ></video-movement-controls>
        </div>
        <div
          class="video-movement-controls-container"
          v-if="useLowLatencyPlayer && supportsPTZ"
        >
          <video-movement-controls
            v-if="supportsPanTilt"
            class="video-movement-arrow-controls"
            @arrowPress="handleRotationInput"
          ></video-movement-controls>
          <div class="zoom-controls" v-if="supportsZoom">
            <v-btn
              x-small
              :ripple="false"
              @click="handleRotationInput('In')"
              icon
              class="zoomButton"
            >
              <v-icon color="#464646">fa-solid fa-plus</v-icon>
            </v-btn>
            <v-btn
              x-small
              :ripple="false"
              @click="handleRotationInput('Out')"
              icon
              class="zoomButton"
            >
              <v-icon color="#464646">fa-solid fa-minus</v-icon></v-btn
            >
          </div>
        </div>
      </div>
    </div>
    <template v-if="cameraDisabled && !offline">
      <div class="d-flex flex-column camera-disabled">
        <h4 class="white--text text-center">{{ $t("Camera disabled") }}</h4>
        <v-btn class="mt-3" @click="enableCamera">{{
          $t("Enable Camera")
        }}</v-btn>
      </div>
    </template>
    <v-img
      v-show="offline"
      :src="cameraOffline"
      class="offline-camera"
      :aspect-ratio="16 / 9"
      contain
    ></v-img>
    <v-toolbar
      flat
      :height="fullscreen || !isMultiview ? 36 : 24"
      class="camera-toolbar bottom"
      :class="{ displayNone: cursorHide }"
    >
      <v-btn icon :small="isMultiview" @click="togglePlay">
        <svg
          id="play-svg-2"
          ref="playSvg2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <path ref="path2" d="M11,8 L26,16 11,24 11,8">
            <animate
              ref="animate2"
              attributeName="d"
              fill="freeze"
              dur="0.1s"
              calc-mode="spline"
              keySplines="0.19 1 0.22 1"
            />
          </path>
        </svg>
      </v-btn>
      <template v-if="showAudioControls">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              small
              dense
              @click="setPlayerVolume(true)"
            >
              <v-icon color="secondary" size="18">
                {{
                  audioMuted
                    ? "fa-volume-mute"
                    : audioVolume < 8
                    ? "fa-volume-down"
                    : "fa-volume-up"
                }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ audioMuted ? $t("Unmute") : $t("Mute") }}</span>
        </v-tooltip>
        <div class="live-volume-slider" v-show="!audioMuted">
          <v-slider
            track-color="secondary"
            thumb-color="secondary"
            hide-details
            dense
            v-model="audioVolume"
            min="0"
            max="10"
            @change="setPlayerVolume(false)"
          ></v-slider>
        </div>
      </template>

      <v-btn
        v-if="!(cameraDisabled || offline)"
        icon
        :small="isMultiview"
        @click="handleSnapshotButtonClick"
      >
        <v-icon :size="fullscreen || !isMultiview ? 20 : 18" color="white"
          >fa-camera</v-icon
        >
      </v-btn>
      <v-btn
        v-if="rights && rights.editCamera"
        icon
        :small="isMultiview"
        @click="$router.push({ path: `/settings/cameras/${camera.cameraId}` })"
      >
        <v-icon color="white" :size="fullscreen || !isMultiview ? 20 : 18"
          >fa-cog</v-icon
        >
      </v-btn>
      <v-btn
        v-if="!(cameraDisabled || offline || isMultiview || !gpsDetected)"
        icon
        @click="$emit('emit-toggle-map')"
      >
        <v-icon size="18" color="white">fa-map</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="rights && rights.playback"
        depressed
        :small="isMultiview"
        class="footageButton"
        :class="{ multiview: isMultiview }"
        text
        color="transparent"
        :ripple="false"
        @click="
          $router.push({
            name: 'Footage',
            params: { cameraId: camera.cameraId },
          })
        "
      >
        <v-icon
          color="white"
          :class="{ 'mr-1': !fullscreen && isMultiview }"
          :size="fullscreen || !isMultiview ? 18 : 14"
          left
          >fa-history</v-icon
        >
        <span class="whiteText">{{ $t("Footage") }}</span>
      </v-btn>
      <v-btn
        v-if="fullscreenEnabled && (!maximize || !isMultiview)"
        icon
        :small="isMultiview"
        @click="toggleFullscreen"
      >
        <v-icon
          v-if="fullscreen"
          :size="fullscreen && isMultiview ? 20 : 18"
          color="white"
          >fa-compress</v-icon
        >
        <v-icon v-else :size="fullscreen && isMultiview ? 20 : 18" color="white"
          >fa-expand</v-icon
        >
      </v-btn>
    </v-toolbar>
    <div
      :class="[!fullscreen && isMultiview ? 'timeStamp' : 'timeStampFull']"
      v-if="
        !offline &&
        !statusLLLP &&
        !cameraDisabled &&
        !supportAccessEnabled &&
        !(
          !useLowLatencyPlayer &&
          (playState === 'loading' || isBuffering) &&
          type != 'mjpeg'
        )
      "
    >
      {{ timeStamp }} <br />
      <span v-if="supportsDewarping && qrCodeInfo && !isNaN(qrCodeInfo.speed)">
        {{ travelSpeed }}
      </span>
    </div>
    <DebugTools
      :singleView="false"
      :threeZone="threeZone"
      :debugTools="openDebugTools"
      @showTool="openDebugTools = false"
      :debugData="debugData"
      :camera="camera"
      @setBufferLength="setBufferLength"
      :bufferSizes="bufferSizes"
      :selectedBuffer="selectedBuffer"
    ></DebugTools>
    <FootageExportDialog
      class="ml-1"
      :camera="camera"
      :currentTime="currentTime"
      :mini="false"
      :offset="offset"
      :resolution="resolution"
      :openSnapshot="openSnapshot"
      :showOnlySnap="true"
    ></FootageExportDialog>
  </fullscreen>
</template>

<script>
import Hls from "hls.js";
import { store } from "@/store";
import { saveAs } from "file-saver";
import DebugTools from "../Base/DebugTools";
import Sentry, {
  SentryProfiling,
} from "@eencloud/core-components/src/plugins/sentry";
import VideoMovementControls from "@eencloud/core-components/src/components/base/VideoMovementControls";
import { getTimeStamp } from "@eencloud/core-components/src/service/time";
import lowLatencyLivePlayer from "@/components/LowLatencyLivePlayer/LivePlayer";
import parseQrCode from "@eencloud/core-components/src/service/qrCodeParser";
import { component } from "vue-fullscreen";
import FootageExportDialog from "@eencloud/core-components/src/components/base/FootageExportDialog";

const auth = require("@/service/auth");

export default {
  name: "LiveVideo",
  components: {
    VideoMovementControls,
    DebugTools,
    lowLatencyLivePlayer,
    FootageExportDialog,
    fullscreen: component,
  },
  props: [
    "camera",
    "cameras",
    "selectedView",
    "detectionArea",
    "maximize",
    "threeZone",
    "cursorHide",
    "rights",
    "isMultiview",
    "isMuted",
  ],
  data() {
    return {
      hls: null,
      hlsRetries: 3,
      hlsFinishProfiling: null,
      debugData: {
        type: null,
        audioType: null,
        bitrate: null,
        duration: null,
        latency: null,
        time: null,
        bandwidth: null,
        droppedFrames: null,
        queueSizes: null,
        qrTimestamp: null,
        audioBufferSize: null,
        currentBufferLength: null,
      },
      openDebugTools: false,
      bitrate: null,
      XOffset: null,
      YOffset: null,
      playing: true,
      counter: 0,
      fullscreen: false,
      timeSetup: null,
      timeStampLllpInMs: 0,
      litrate: null,
      mouseMoved: false,
      type: null,
      mjpegView: true,
      mouseMovedTimeout: null,
      playerOptions: {
        bigPlayButton: false,
        fluid: true,
        autoplay: true,
        controls: false,
        responsive: true,
        inactivityTimeout: 0,
        playbackRates: [1.0, 2.0, 5.0, 10.0],
        errorDisplay: false,
        loadingSpinner: false,
        loop: false,
        controlBar: {
          volumePanel: {
            inline: false,
          },
          durationDisplay: true,
        },
      },
      connectionImages: {
        "3g-1": this.getImgUrl("3g-1.png"),
        "3g-2": this.getImgUrl("3g-2.png"),
        "3g-3": this.getImgUrl("3g-3.png"),
        "4g-1": this.getImgUrl("4g-1.png"),
        "4g-2": this.getImgUrl("4g-2.png"),
        "4g-3": this.getImgUrl("4g-3.png"),
        "wifi-1": this.getImgUrl("wifi-1.png"),
        "wifi-2": this.getImgUrl("wifi-2.png"),
        "wifi-3": this.getImgUrl("wifi-3.png"),
      },
      strengths: {
        high: 3,
        medium: 2,
        low: 1,
      },
      cameraOffline: require("@eencloud/core-components/src/assets/images/camera-offline.svg"),
      playState: null,
      isBuffering: true,
      isFullscreen: false,
      isPlaying: false,
      bufferingTimeout: null,
      connectingTimeout: null,
      stateMessage: "Connecting",
      statusLLLP: null,
      failingLLLP: null,
      lowbandwidthMessageLLLP: null,
      connectivityIssuesMsg: {
        color: "color-title-light",
        width: 0,
        timeout: 10000,
      },
      wifiImages: {
        low: require("@eencloud/core-components/src/assets/images/wifi-1.svg"),
        medium: require("@eencloud/core-components/src/assets/images/wifi-2.svg"),
        high: require("@eencloud/core-components/src/assets/images/wifi-3.svg"),
      },
      immervisionPlayer: null,
      immervisionControl: null,
      mjpegKey: null,
      selectedBuffer: 3000, //this buffer applies only to the low latency player
      bufferSizes: [
        { text: "No buffer", value: 0 },
        { text: "200 ms", value: 200 },
        { text: "500 ms", value: 500 },
        { text: "1 sec", value: 1000 },
        { text: "2 sec", value: 2000 },
        { text: "3 sec", value: 3000 },
        { text: "4 sec", value: 4000 },
        { text: "5 sec", value: 5000 },
        { text: "6 sec", value: 6000 },
      ],
      qrCodeInfo: null,
      gpsDetected: false,
      audioMuted: true,
      audioVolume: 10,
      showAudioControls: false,
      mobileNetwork: null,
      openSnapshot: false,
      manualLiveTimeSecondCounter: 0,
      useLowLatencyPlayer: !!window.VideoDecoder
    };
  },
  computed: {
    token() {
      return auth.getToken();
    },
    currentTime() {
      return new Date().getTime();
    },
    resolution() {
      return this.camera && this.camera.footageSettings
        ? this.camera.footageSettings.rec.video.resolution
        : null;
    },
    offset() {
      const offsetToUTC = new Date().getTimezoneOffset();
      return (this.camera.timeOffset - offsetToUTC) * 60000;
    },
    online() {
      return this.camera && this.camera.status
        ? this.camera.status.online
        : null;
    },
    offline() {
      return this.camera && this.camera.status
        ? !this.camera.status.online
        : null;
    },
    offlineCamera() {
      return this.offline ? this.cameraOffline : this.greyImg;
    },
    player() {
      return this.$refs.hasOwnProperty("videoPlayer")
        ? this.$refs.videoPlayer.player
        : null;
    },
    mjpegUrl() {
      return this.camera.streams
        ? this.camera.streams[0].urls.mjpegHttps +
            `&access_token=${auth.getToken()}`
        : null;
    },
    resolution() {
      return this.camera && this.camera.footageSettings
        ? this.camera.footageSettings.rec.video.resolution
        : null;
    },
    wifiStrengthClass() {
      return this.camera &&
        this.camera.wifiStatus &&
        this.camera.wifiStatus.strength
        ? `wifi-${
            this.strengths[this.camera.wifiStatus.strength.toLowerCase()]
          }`
        : null;
    },
    videoPlayer() {
      return this.$refs.hasOwnProperty("hlsPlayer")
        ? this.$refs.hlsPlayer
        : null;
    },
    fullscreenEnabled() {
      return (
        document.fullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.documentElement.webkitRequestFullScreen
      );
    },
    supportsDewarping() {
      return this.camera && this.camera.cameraCapabilities
        ? this.camera.cameraCapabilities.dewarping
        : false;
    },
    supportsPTZ() {
      return this.camera && this.camera.cameraCapabilities
        ? this.camera.cameraCapabilities.ptz
        : false;
    },
    supportsZoom() {
      return this.camera && this.camera.ptzCapabilities
        ? this.camera.ptzCapabilities.zoom
        : false;
    },
    supportsPanTilt() {
      return this.camera && this.camera.ptzCapabilities
        ? this.camera.ptzCapabilities.panTilt
        : false;
    },
    cameraDisabled() {
      return this.camera?.cameraMode
        ? this.camera.cameraMode.mode === "private"
        : false;
    },
    multiPartStreamUrl() {
      return (
        this.camera.streams[0].urls.multipartaudioHttps +
          `&access_token=${this.token}` ?? ""
      );
    },
    framerate() {
      return this.camera?.footageSettings?.rec.video.framerate || 25;
    },
    travelSpeed() {
      return `${this.$t("Speed")}: ${Math.round(
        this.qrCodeInfo.speed
      )} km/h, ${Math.round(this.qrCodeInfo.speed * 0.621371)} mph`;
    },
    audioCodec() {
      if (this.showAudioControls) {
        this.debugData.audioType =
          this.camera &&
          this.camera.streams &&
          this.camera.streams[0].outputStreamCodecs.audio;
      } else {
        this.debugData.audioType = this.$t("No audio detected");
      }
      return (
        this.camera &&
        this.camera.streams &&
        this.camera.streams[0].outputStreamCodecs.audio
      );
    },
    timeStamp() {
      let date;
      if (this.useLowLatencyPlayer) {
        if (this.timeStampLllpInMs) date = new Date(this.timeStampLllpInMs);
        else return "";
      } else if (this.manualLiveTimeSecondCounter) {
        date = new Date();
      }
      return (
        getTimeStamp(
          this.camera.timeOffset,
          date,
          store.getters.accountTimeZoneOffset
        ) ?? ""
      );
    },
    supportAccessEnabled() {
      return this.$store.getters.supportAccess;
    },
  },
  watch: {
    isMuted: function () {
      this.audioMuted = this.isMuted;
    },
    camera: async function (newVal, oldVal) {
      if (this.camera && oldVal !== newVal) {
        this.showAudioControls = false;
        this.audioMuted = true;
        this.qrCodeInfo = null;
        this.gpsDetected = false;
        this.videoPauseEvent();
        await this.getFootageSettings();
        await this.getCapabilities();
        if (this.online && this.camera.status.connectionType === "Mobile") {
          this.getMobileData();
        }
        if (this.hls) {
          this.hls.stopLoad();
          this.hls.destroy();
          this.hls = null;
          this.hlsRetries = 3;
          this.isBuffering = false;
          clearInterval(this.timeSetup);
          this.timeSetup = setInterval(
            () => this.manualLiveTimeSecondCounter++,
            1000
          );
        }
        if (this.online && !this.cameraDisabled && !this.hls) {
          this.stream();
        }
        if (this.type == "mjpeg") {
          this.mjpegKey = Date.now();
          this.mjpegView = true;
        }
        if (this.immervisionPlayer) {
          this.immervisionPlayer = null;
          this.immervisionControl = null;
          this.$refs.dewarpingContainer.innerHTML = "";
        }
      }
    },
    online: function (value) {
      if (this.immervisionPlayer && value) this.immervisionPlayer = null;
      if (!this.cameraDisabled && !this.hls) {
        this.stream();
      }
    },
    isBuffering: function (value) {
      if (value) {
        this.bufferingTimeout = setTimeout(() => {
          if (this.hlsFinishProfiling) {
            this.hlsFinishProfiling(true);
            this.hlsFinishProfiling = null;
            Sentry.configureScope((scope) =>
              scope.setTransactionName(this.$options.name)
            );
          }
          this.$refs.hlsPlayer.play().catch(() => {});
          try {
            this.streamCameraHls();
          } catch (error) {
            Sentry.captureException(error);
          }
        }, 30000);
      } else {
        clearTimeout(this.bufferingTimeout);
      }
    },
    stateMessage: function (value) {
      if (value == "Connecting") {
        this.connectingTimeout = setTimeout(async () => {
          if (this.hlsFinishProfiling) {
            this.hlsFinishProfiling(true);
            this.hlsFinishProfiling = null;
            Sentry.configureScope((scope) =>
              scope.setTransactionName(this.$options.name)
            );
          }
          this.$refs.hlsPlayer.play().catch(() => {});
          try {
            await this.getWifiStatus();
            await this.getStreams();
            this.streamCameraHls();
          } catch (error) {
            Sentry.captureException(error);
          }
        }, 30000);
      } else {
        clearTimeout(this.connectingTimeout);
      }
    },
    cameraDisabled: async function (newVal, oldVal) {
      if (this.camera && oldVal !== newVal) {
        if (this.hls) {
          this.hls.stopLoad();
          this.hls.destroy();
          this.hls = null;
          this.hlsRetries = 3;
          this.isBuffering = false;
          clearInterval(this.timeSetup);
          this.timeSetup = setInterval(
            () => this.manualLiveTimeSecondCounter++,
            1000
          );
        } else if (this.type === "mjpeg") {
          this.mjpegView = false;
        }
        if (!newVal && this.online) {
          try {
            this.stream();
          } catch (error) {
            console.error(error);
          }
        }
      }
    },
    maximize: function () {
      if (!this.isMultiview) this.toggleFullscreen();
    },   
    useLowLatencyPlayer: function (value) {
      if (value) {
        this.hls && this.hls.stopLoad();
        this.debugData.type = "WebCodecs";
        setTimeout(() => this.videoPlayEvent(), 100);
      }
    },
    supportsDewarping: function (value) {
      if (!value) {
        this.immervisionPlayer = null;
        this.immervisionControl = null;
      }
    },
  },
  async mounted() {
    Sentry.configureScope((scope) =>
      scope.setTransactionName(this.$options.name)
    );
    await this.getCapabilities();
    await this.getFootageSettings();
    if (this.camera && this.online && !this.cameraDisabled) {
      this.stream();
    }
    if (
      this.camera &&
      this.online &&
      this.camera.status.connectionType === "Mobile"
    ) {
      this.getMobileData();
    }
    window.addEventListener("mousemove", this.mouseMoveListen);
  },
  created() {
    this.useLowLatencyPlayer = !this.threeZone && !!window.VideoDecoder;
    this.timeSetup = setInterval(
      () => this.manualLiveTimeSecondCounter++,
      1000
    );
  },
  beforeDestroy() {
    if (this.immervisionPlayer) this.destroyDewarpPlayer();
    if (this.hls) {
      this.hls.stopLoad();
      this.hls.destroy();
    }
    if (this.player && !this.player.paused()) this.player.dispose();
    if (this.streamer && this.streamer.connected) {
      if (this.type === "hls") {
        if (this.online && this.hls) {
          console.log("Now stop loading hls stream", this.hls);
          this.hls.stopLoad();
        }
      } else {
        this.streamer.disconnect();
      }
    }
    window.removeEventListener("mousemove", this.mouseMoveListen);
  },
  destroyed() {
    clearInterval(this.timeSetup);
  },
  methods: {
    async getCapabilities() {
      try {
        if (!this.camera.cameraCapabilities) {
          const cameraCapabilities = await this.restapi.getCameraCapabilities(
            this.camera.cameraId
          );
          this.$set(this.camera, "cameraCapabilities", cameraCapabilities);
        }
        if (
          !this.camera.ptzCapabilities &&
          this.camera.cameraCapabilities.ptz
        ) {
          const ptzCapabilities = await this.restapi.getCameraPTZCapabilities(
            this.camera.cameraId
          );
          this.$set(this.camera, "ptzCapabilities", ptzCapabilities);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getFootageSettings() {
      try {
        if (!this.camera.footageSettings) {
          const settings = await this.restapi.getCameraFootageSettings(
            this.camera.cameraId
          );
          this.$set(this.camera, "footageSettings", settings);
        }
      } catch (error) {
        console.log(error);
      }
    },
    stream() {
      SentryProfiling(
        "Web App",
        {},
        "Stream load time",
        async (finishProfiling) => {
          this.hlsFinishProfiling = finishProfiling;
          if (this.useLowLatencyPlayer) {
            this.setStatusLLLP("Connecting");
            await this.getStreamsMulti();
            if (this.hlsFinishProfiling) {
              this.hlsFinishProfiling(false);
              this.hlsFinishProfiling = null;
            }
            Sentry.configureScope((scope) =>
              scope.setTransactionName(this.$options.name)
            );
            this.getWifiStatus();
          } else {
            try {
              if (this.camera?.cameraCapabilities?.wifi)
                await this.getWifiStatus();
              await this.getStreams();
              this.debugData.type = this.type;
              if (this.online && this.type === "hls") {
                this.streamCameraHls();
              } else {
                if (this.hlsFinishProfiling) {
                  this.hlsFinishProfiling(false);
                  this.hlsFinishProfiling = null;
                }
                Sentry.configureScope((scope) =>
                  scope.setTransactionName(this.$options.name)
                );
              }
              if (this.type === "mjpeg") {
                this.mjpegKey = Date.now();
                this.mjpegView = true;
                this.stateMessage = "Buffering";
                this.isPlaying = true;
                this.$nextTick(() => {
                  this.morphSVG();
                });
              }
            } catch (error) {
              Sentry.captureException(error);
            }
          }
        }
      );
    },
    videoPlayEvent() {
      this.isPlaying = true;
      this.morphSVG();
      this.$emit("isPlaying");
      if (this.supportsDewarping) {
        this.$nextTick(() => {
          this.setupDewarpPlayer();
        });
      }
    },
    videoPauseEvent() {
      this.isPlaying = false;
      this.morphSVG();
    },

    mouseMoveListen() {
      this.mouseMoved = true;
      clearTimeout(this.mouseMovedTimeout);
      this.mouseMovedTimeout = setTimeout(() => {
        this.mouseMoved = false;
      }, 2000);
    },

    togglePlay() {
      if (this.useLowLatencyPlayer) {
        if (this.$refs.lowLatencyPlayer) {
          if (this.$refs.lowLatencyPlayer.isPlaying) {
            this.$refs.lowLatencyPlayer.stop();
          } else {
            this.$refs.lowLatencyPlayer.start();
          }
        }
      } else {
        if (this.type == "hls") {
          if (this.isPlaying) {
            this.$refs.hlsPlayer.pause();
            this.hls.stopLoad();
            this.isBuffering = false;
            this.playState = "stopped";
            clearTimeout(this.timeSetup);
          } else {
            this.$refs.hlsPlayer.play().catch(() => {});
            try {
              this.streamCameraHls();
            } catch (error) {
              Sentry.captureException(error);
            }
            this.timeSetup = setInterval(
              () => this.manualLiveTimeSecondCounter++,
              1000
            );
          }
        } else {
          this.mjpegView = !this.mjpegView;
          if (this.mjpegView) {
            this.timeSetup = setInterval(
              () => this.manualLiveTimeSecondCounter++,
              1000
            );
            this.videoPlayEvent();
          } else {
            clearTimeout(this.timeSetup);
            this.videoPauseEvent();
          }
        }
      }
    },
    morphSVG() {
      try {
        // let path = this.$refs.path;
        // let anim = this.$refs.animate;

        let path2 = this.$refs.path2;
        let anim2 = this.$refs.animate2;

        let firstPath = "M 10 8 L 26 16 L 26 16 L 10 24 L 10 8";
        let secondPath = "M 8 8 L 24 8 L 24 24 L 8 24 L 8 8";

        if (this.isPlaying) {
          // path.setAttribute("d", secondPath);
          // anim.setAttribute("from", firstPath);
          // anim.setAttribute("to", secondPath);

          path2.setAttribute("d", secondPath);
          anim2.setAttribute("from", firstPath);
          anim2.setAttribute("to", secondPath);
        } else {
          // path.setAttribute("d", firstPath);
          // anim.setAttribute("from", secondPath);
          // anim.setAttribute("to", firstPath);

          path2.setAttribute("d", firstPath);
          anim2.setAttribute("from", secondPath);
          anim2.setAttribute("to", firstPath);
        }

        // anim.beginElement();
        anim2.beginElement();
      } catch (error) {
        console.error(error);
      }
    },
    async getWifiStatus() {
      this.stateMessage = "Connecting";
      this.playState = "loading";
      this.playing = true;
      if (this.camera) {
        const that = this;
        try {
          let capability = this.camera.hasOwnProperty("cameraCapabilities")
            ? this.camera.cameraCapabilities
            : await this.restapi.getCameraCapabilities(this.camera.cameraId);
          if (capability?.wifi) {
            try {
              let wifiStatus = await this.restapi.getCameraWifiStatus(
                this.camera.cameraId
              );
              that.$set(that.camera, "wifiStatus", wifiStatus);
            } catch (error) {
              console.log(error);
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    removeVideo() {
      this.restapi.cancelAll();
      if (this.player && !this.player.paused()) this.player.dispose();
      if (this.hls) {
        this.hls.stopLoad();
      }
      if (this.streamer && this.streamer.connected) {
        if (this.type === "hls") {
          if (this.online && this.hls) {
            this.hls.stopLoad();
          }
        } else {
          this.streamer.disconnect();
        }
      }
      if (this.hls) this.hls.destroy();
      if (this.isMultiview) {
        this.$emit("removeVideo");
      } else {
        this.$router.push({ path: `/live` });
      }
    },
    openTool() {
      this.counter++;
      setTimeout(() => {
        this.counter = 0;
      }, 1000);
      if (this.counter == 4) {
        this.openDebugTools = true;
        this.counter = 0;
      }
    },

    async getStreams() {
      if (this.camera) {
        try {
          const streams = await this.restapi.getCameraStreams(
            this.camera.cameraId
          );
          const cameraIndex = this.cameras.findIndex(
            (c) => c.cameraId === this.camera.cameraId
          );
          if (this.camera.streams) {
            this.$set(this.camera, "streams", [
              Object.assign({}, streams[0], this.camera.streams[0]),
            ]);
            if (cameraIndex !== -1)
              this.$set(this.cameras[cameraIndex], "streams", [
                Object.assign(
                  {},
                  streams[0],
                  this.cameras[cameraIndex].streams[0]
                ),
              ]);
          } else {
            this.$set(this.camera, "streams", streams);
            if (cameraIndex !== -1)
              this.$set(this.cameras[cameraIndex], "streams", streams);
          }
          this.type =
            streams[0].outputStreamCodecs.video == "mjpeg" ? "mjpeg" : "hls";
          if (this.type == "mjpeg") this.playing = true;
        } catch (error) {
          console.error(error);
        }
      }
    },
    async getStreamsMulti() {
      if (this.camera) {
        if (this.camera.streams) {
          if (this.camera.streams[0].outputStreamCodecs?.video === "mjpeg") {
            this.type = "mjpeg";
            this.debugData.type = "mjpeg";
            this.playing = true;
            return;
          } else {
            this.type = "WebCodecs";
            this.debugData.type = "WebCodecs";
          }
        } else
          try {
            const [oldStreamsApi, newStreamsApi] = await Promise.all([
              this.restapi.getCameraStreams(this.camera.cameraId),
              this.restapi.getCameraStreamsMulti(this.camera.cameraId),
            ]);
            if (oldStreamsApi[0].outputStreamCodecs.video === "mjpeg") {
              this.type = "mjpeg";
              this.debugData.type = "mjpeg";
              this.playing = true;
            } else if (oldStreamsApi[0].outputStreamCodecs.video === "h264") {
              this.type = "WebCodecs";
              this.debugData.type = "WebCodecs";
            }
            const cameraIndex = this.cameras.findIndex(
              (c) => c.cameraId === this.camera.cameraId
            );
            if (cameraIndex !== -1)
              this.$set(this.cameras[cameraIndex], "streams", [
                Object.assign(oldStreamsApi[0], newStreamsApi[0]),
              ]);
          } catch (error) {
            console.error(error);
          }
      }
    },
    hlsRetryLoad() {
      this.hlsRetries -= 1;
      const token = this.token;
      const hlsStream = this.camera.streams
        ? this.camera.streams[0].urls.hlsHttps + `&access_token=${token}`
        : null;
      if (hlsStream && token && !this.playing) {
        this.hls.loadSource(hlsStream);
        this.hls.startLoad();
      }
    },
    streamCameraHls() {
      const that = this;
      this.streamer = { connected: false };
      if (this.camera && this.camera.streams && this.videoPlayer) {
        const config = {
          debug: false,
          autoStartLoad: false,
          liveSyncDurationCount: 1,
        };
        this.hls = new Hls(config);
        const video = this.$refs.hlsPlayer;
        this.hls.on("play", function (event, data) {
          that.playState = "playing";
        });
        this.hls.on("pause", function (event, data) {
          that.playState = "paused";
        });
        const token = this.token;
        that.isBuffering = false;
        const hlsStream = this.camera.streams
          ? this.camera.streams[0].urls.hlsHttps + `&access_token=${token}`
          : null;
        if (hlsStream && token) {
          that.playState = "loading";
          that.stateMessage = "Connecting";
          this.hls.loadSource(hlsStream);
          this.hls.attachMedia(video);
          this.hls.on(Hls.Events.MANIFEST_PARSED, function () {
            that.hlsRetries = 3;
            that.isBuffering = true;
            that.stateMessage = "Buffering";
            that.hls.startLoad();
            that.streamer.connected = true;
            that.playState = "playing";
            that.$refs.hlsPlayer.play().catch(() => {});
            that.morphSVG();
          });
          this.hls.on(Hls.Events.ERROR, function (event, data) {
            that.playState = null;
            if (data.details === Hls.ErrorDetails.BUFFER_STALLED_ERROR) {
              that.isBuffering = true;
              that.stateMessage = "Buffering";
            }
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  console.log(
                    "Fatal network error encountered, try to recover"
                  );
                  if (that.supportAccessEnabled) {
                    store.dispatch("toastMessage", {
                      text: that.$t(
                        "No video is available when using support-access"
                      ),
                      color: "error",
                      showing: true,
                      timeout: -1,
                      support: false,
                    });
                    that.stateMessage = null;
                    that.$refs.hlsPlayer.style = "";
                    that.$refs.hlsPlayer.poster = `${that.restapi.baseURL}/rest/v2.4/cameras/${that.camera.cameraId}/snapshot?resolution=1920x1080&access_token=${that.restapi.token}`;
                  }
                  if (that.hlsRetries > 0) {
                    that.playState = "loading";
                    setTimeout(() => that.hlsRetryLoad(), 1000);
                  } else {
                    store.dispatch("toastMessage", {
                      showing: true,
                      text:
                        that.$t(
                          "Could not start the live video for the camera "
                        ) + that.camera.name,
                      timeout: -1,
                      color: "error",
                      support: true,
                    });
                  }
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  console.log("Fatal media error encountered, try to recover");
                  if (that.hlsRetries > 0 && !this.playing) {
                    that.playState = "loading";
                    setTimeout(() => that.hlsRetryLoad(), 1000);
                  } else {
                    store.dispatch("toastMessage", {
                      showing: true,
                      text:
                        that.$t(
                          "Could not start the live video for the camera"
                        ) + that.camera.name,
                      timeout: -1,
                      color: "error",
                      support: true,
                    });
                  }
                  break;
                default:
                  that.hls.destroy();
                  break;
              }
            }
          });
          this.hls.on(Hls.Events.FRAG_BUFFERED, (event, data) => {
            this.debugData.bitrate = Math.round(
              (8 * data.stats.total) /
                (data.stats.tbuffered - data.stats.trequest)
            );
            this.debugData.duration = data.frag.duration;
            this.isBuffering = false;
            if (this.hlsFinishProfiling) {
              this.hlsFinishProfiling(true);
              this.hlsFinishProfiling = null;
              Sentry.configureScope((scope) =>
                scope.setTransactionName(this.$options.name)
              );
            }
            this.$emit("isPlaying");
          });
          this.hls.on(
            Hls.Events.STREAM_STATE_TRANSITION,
            function (event, data) {
              if (
                data.previousState === "WAITING_LEVEL" &&
                data.nextState === "IDLE"
              ) {
                that.playState = "loading";
              } else if (data.nextLevel === "STOPPED") {
                that.playState = "paused";
                that.hls.stopLoad();
              }
            }
          );
        }
      }
    },
    onQrCode(qrCodeString) {
      this.qrCodeInfo = parseQrCode(qrCodeString);

      if (this.qrCodeInfo.latitude && this.qrCodeInfo.longitude) {
        this.gpsDetected = true;
        this.$emit("emit-gps-coordinates", {
          latitude: this.qrCodeInfo.latitude,
          longitude: this.qrCodeInfo.longitude,
        });
      }

      // create a timestamp from the qrcode for the debugtools
      if (
        this.openDebugTools &&
        this.qrCodeInfo.timestamp &&
        store.getters.accountTimeZoneOffset &&
        this.camera.timeOffset
      ) {
        this.debugData.qrTimestamp = getTimeStamp(
          this.camera.timeOffset,
          new Date(this.qrCodeInfo.timestamp),
          store.getters.accountTimeZoneOffset
        );
      }
    },
    setupDewarpPlayer() {
      console.log("setup dewarp player");
      try {
        const container = this.$refs.dewarpingContainer;
        const video =
          this.type === "hls"
            ? this.$refs.hlsPlayer
            : this.$refs.lowLatencyPlayer.videoElement;

        const videoSrc = this.camera.streams
          ? this.camera.streams[0].urls.hlsHttps + `&access_token=${this.token}`
          : null;

        if (video.canPlayType("application/vnd.apple.mpegurl")) {
          this.hls.detachMedia();
          this.hls.destroy();

          video.src = videoSrc;
          this.isBuffering = true;
          this.stateMessage = "Buffering";

          video.addEventListener("loadeddata", () => {
            this.isBuffering = false;
            this.playState = "playing";
          });
          video.addEventListener("loadedmetadata", () => {
            video.play().catch(() => {});
          });
        }

        const options = {
          video: video,
          orientation: "WALL",
          onVideoFrame: this.updateBodycamRotation,
          onStateChange: (newState, state) => {
            console.log("DOM.onStateChange:", newState);
          },
          onQrCode: this.onQrCode,
        };

        if (this.immervisionPlayer) {
          this.immervisionPlayer.setState(options);
        } else {
          this.immervisionPlayer = new window.immervisionSDK.IVEViewer(
            { target: container },
            options
          );
          this.immervisionPlayer.view.zoom(2.8);
        }

        this.immervisionPlayer.video_html.crossOrigin = "anonymous";
        this.immervisionPlayer.video_html.loop = false;

        this.immervisionControl = new window.immervisionSDK.Control(container, {
          onZoom: this.immervisionPlayer.view.zoom,
          onRotate: this.rotateDewarp,
        });
      } catch (error) {
        console.error(error);
      }
    },
    destroyDewarpPlayer() {
      this.immervisionPlayer.video_html.pause();
      this.immervisionPlayer.video_html.removeAttribute("src");
      this.immervisionPlayer.video_html.load();

      const container = this.$refs.dewarpingContainer;
      if (container) container.innerHTML = "";

      this.immervisionPlayer.destroy();

      this.immervisionPlayer = null;
      this.immervisionControl = null;
    },
    updateBodycamRotation() {
      this.immervisionPlayer.setState({
        rotation: {
          x: 0,
          y: 0,
          z: 0,
        },
      });
    },
    handleRotationInput(direction) {
      if (this.supportsDewarping) {
        switch (direction) {
          case "right":
            this.rotateDewarp(0, 0.05, false);
            break;
          case "up":
            this.rotateDewarp(-0.05, 0, false);
            break;
          case "left":
            this.rotateDewarp(0, -0.05, false);
            break;
          case "down":
            this.rotateDewarp(0.05, 0, false);
            break;
        }
      } else if (this.supportsPTZ) {
        switch (direction) {
          case "right":
            this.rotatePTZ("Right");
            break;
          case "up":
            this.rotatePTZ("Up");
            break;
          case "left":
            this.rotatePTZ("Left");
            break;
          case "down":
            this.rotatePTZ("Down");
            break;
          case "In":
            this.rotatePTZ("In");
            break;
          case "Out":
            this.rotatePTZ("Out");
            break;
        }
      }
    },
    rotatePTZ(direction) {
      this.restapi.ptzCameraControl(this.camera.cameraId, direction);
    },
    rotateDewarp(x, y, inverted = true) {
      const finalX = inverted ? -x : x;
      const finalY = inverted ? -y : y;
      this.immervisionPlayer.view.rotate(finalX, finalY);
    },
    streamVideoMjpeg() {
      const mjpegUrl = this.camera.streams
        ? this.camera.streams[0].urls.mjpegHttps +
          `&access_token=${auth.getToken()}`
        : null;
      if (mjpegUrl && this.$refs.videoPlayer)
        this.$refs.videoPlayer.src = mjpegUrl;
    },
    async saveSnapshot() {
      try {
        const img = await this.restapi.getSnapshot(
          this.camera.cameraId,
          this.resolution
        );
        const data = img.replace(/^data:image\/\w+;base64,/, "");
        const buf = Buffer.from(data, "base64");
        const blob = new Blob([buf]);
        saveAs(blob, "snapshot.jpg");
      } catch (error) {
        console.error(error);
      }
    },
    wifiImageForStrength(strength) {
      return this.connectionImages[
        `wifi-${this.strengths[strength.toLowerCase()]}`
      ];
    },
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    enableCamera() {
      this.restapi.setCameraMode(this.camera.cameraId, {
        mode: this.camera.cameraMode.previousMode,
      });
    },
    setBandwidth(bandwidth) {
      this.debugData.bandwidth = bandwidth;
    },
    setLatency(latency) {
      this.debugData.latency = latency;
    },
    setBufferLength(time_ms) {
      this.selectedBuffer = time_ms;
    },
    setQueueSizes(queues) {
      this.debugData.queueSizes = queues;
    },
    setStatusLLLP(statusLLLPMessage) {
      this.statusLLLP = statusLLLPMessage;
    },
    setFailing() {
      this.failingLLLP = "Failed to load video";
    },
    setLowbandwidth(lowbandwidthMessage) {
      this.lowbandwidthMessageLLLP = lowbandwidthMessage;
    },
    setPlayerVolume(toggleMute) {
      if (toggleMute) {
        if (this.audioMuted) {
          this.audioMuted = false;
          this.$emit("audioPlay", this.camera.cameraId);
        } else this.audioMuted = true;
      } else {
        if (this.audioVolume) {
          if (this.audioMuted) this.audioMuted = false;
        } else if (this.audioVolume === 0) {
          this.audioMuted = true;
        }
      }
    },

    async getMobileData() {
      try {
        this.mobileNetwork = await this.restapi.getCameraMobileNetwork(
          this.camera.cameraId
        );
      } catch (error) {
        console.error(error);
      }
    },

    mobileNetworkImage() {
      if (this.mobileNetwork && this.mobileNetwork.strength) {
        if (this.mobileNetwork.networkType === "Fourth_Generation") {
          return this.connectionImages[
            `4g-${this.strengths[this.mobileNetwork.strength.toLowerCase()]}`
          ];
        } else if (this.mobileNetwork.networkType === "Third_Generation") {
          return this.connectionImages[
            `3g-${this.strengths[this.mobileNetwork.strength.toLowerCase()]}`
          ];
        }
        return null; // show nothing if it's not 3g or 4g. We don't have images for them.
      }
      return null;
    },

    handleSnapshotButtonClick() {
      if (this.camera.subscriptionRights.daysOfRecordings) {
        this.openSnapshot = !this.openSnapshot;
      } else {
        this.saveSnapshot();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/main";

.video-movement-controls-container {
  height: 100% !important;
  width: 100% !important;
  overflow: hidden;
  &::v-deep canvas {
    display: block !important;
    height: 100% !important;
    border-radius: 8px;
    max-height: 100% !important;
    max-width: 100% !important;
    margin: auto;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0 !important;
  }

  .video-movement-arrow-controls {
    position: absolute;
    z-index: 1;
    right: 30px;
    top: 80px;
  }
}

.player-theater-container {
  position: relative;
  width: 100%;
  height: calc((9 / 16) * 100vw);
  max-height: calc(100vh - 169px);
  .player-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.font-size-1rem {
  font-size: 1rem;
}

.mobile-network {
  filter: invert(1);
  border-radius: 1px !important;
  height: 21px;
  width: 21px;
}

.whiteText {
  color: white;
}

.titleFullscreen {
  display: none;
}
.displayNone {
  display: none !important;
}

.playerLive {
  border-radius: 7px;
}
.video-window {
  display: inline;
}

.footageButton {
  &.multiview {
    font-size: 0.7rem;
  }
}

.live-video {
  background-color: #000000; /* #959595; */
  border-radius: 7px;
  &.singleview {
    position: relative;
  }
  &:fullscreen {
    width: 100vw;
    height: 100vh;
  }
  &:not(:fullscreen) {
    width: 100%;
    height: 100%;
  }
  &.use-width {
    width: calc(100vw - 255px);
  }
  &.detectionArea {
    width: 100%;
    height: 100%;
  }
  .offline-camera {
    ::v-deep .v-image__image {
      height: 50%;
      background-position: center center;
      background-repeat: no-repeat;
      width: 30%;
      left: 38%;
      top: 23%;
    }
  }
  .camera-title {
    position: relative;
    top: 10px;
    left: 15px;
    z-index: 1;
    height: 0;
  }
  .camera-toolbar {
    position: absolute;
    left: 0;
    z-index: 2;
    &.top {
      width: 100%;
      background-color: transparent;
      border-top-right-radius: 7px;
      border-top-left-radius: 7px;
      color: white;
      top: 0;
      padding-top: 1.5px;
      background-color: black;
      opacity: 0.7;
    }
    &.bottom {
      &::v-deep .v-toolbar__content {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      width: 100%;
      display: none;
      bottom: 0px;
      border-bottom-right-radius: 7px;
      border-bottom-left-radius: 7px;
      background-color: black;
      opacity: 0.7;
      &.single {
        width: 100%;
        position: absolute;
      }
      #play-svg-2 {
        fill: white;
        height: 30px;
        width: 30px;
      }
    }
  }
  &:hover {
    .camera-toolbar {
      &.bottom {
        display: block;
      }
      &.top {
        display: block;
      }
    }
  }
  .video-loading {
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    width: 120px;
    margin: 0 auto;
    z-index: 1;
    text-align: center;
    padding: 20px 5px;
    padding-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .video-failing {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0);
    font-size: 26px;
  }
  video {
    background-color: #000000; /* #959595; */
    width: 100%;
    height: auto;
  }
  .svg-image {
    display: inline;
    background-color: white;
    mask: url("~@eencloud/core-components/src/assets/images/wifi-3.svg")
      no-repeat;
    &.wifi-1 {
      mask: url("~@eencloud/core-components/src/assets/images/wifi-1.svg")
        no-repeat;
    }
    &.wifi-2 {
      mask: url("~@eencloud/core-components/src/assets/images/wifi-2.svg")
        no-repeat;
    }
    &.wifi-3 {
      mask: url("~@eencloud/core-components/src/assets/images/wifi-3.svg")
        no-repeat;
    }
  }
  .wifi-icon {
    display: inline;
    mask: url("~@eencloud/core-components/src/assets/images/wifi-3.svg")
      no-repeat;
    &.wifi-1 {
      mask: url("~@eencloud/core-components/src/assets/images/wifi-1.svg")
        no-repeat;
    }
    &.wifi-2 {
      mask: url("~@eencloud/core-components/src/assets/images/wifi-2.svg")
        no-repeat;
    }
    &.wifi-3 {
      mask: url("~@eencloud/core-components/src/assets/images/wifi-3.svg")
        no-repeat;
    }
  }
  >>> .v-image {
    border-radius: $double-border-radius;
  }
  video,
  .video-player {
    height: 99.5%;
    >>> .video-js {
      background-color: #000000; /* #959595; */

      padding: 0;
      .vjs-tech {
        position: relative;

        max-width: 100%;
      }
    }
    &.single {
      >>> .video-js {
        display: inline;
        .vjs-tech {
          width: calc(16 / 9 * 100%);
          height: 100%;
          margin: 0 auto;
        }
      }
      &.use-width {
        >>> .video-js {
          .vjs-tech {
            width: calc(100vw - 255px);
          }
        }
      }
    }
    &.offline {
      >>> .video-js {
        background-color: $color-panel-grey;

        .vjs-tech {
          background-color: $color-panel-grey;
        }
      }
    }
  }
}
.vjs-loading-spinner {
  display: none !important;
  visibility: hidden !important;
  z-index: -1 !important;
}
.maximize {
  height: 100% !important;
  width: 100% !important;
}
.fullscreen {
  overflow-y: hidden !important;
}
.timeStamp {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 20px;
  color: #ffffff9e;
  right: 20px;
  text-shadow: 0 0 5px #000000, 0 0 6px #000000;
}
.timeStampFull {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 35px;
  color: #ffffff9e;
  right: 20px;
  text-shadow: 0 0 5px #000000, 0 0 6px #000000;
}
.colorWhite {
  color: white !important;
}
.camera-disabled {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  z-index: 2;
  font-size: 35px;
}
.connectivity-issues-msg {
  z-index: 2;
  position: absolute;
  top: 0% !important;
  opacity: 0.6 !important;
}
.live-volume-slider {
  width: 50px;
}
.zoom-controls {
  position: absolute;
  z-index: 2;
  top: 140px;
  right: 32px;
  padding-bottom: 2px;
  padding-right: 2px;
  padding-left: 2px;
  opacity: 0.6;
  background: white;
  border-radius: 4px;
  -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.7));
  &:hover {
    opacity: 1;
  }
}
.zoomButton:active {
  background: $primary;
}
</style>
