<template>
  <v-navigation-drawer
    :value="open"
    @input="(val) => emit('update', val)"
    right
    fixed
    temporary
    class="event-search-sidebar"
    width="420"
  >
    <v-list dense>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ t("Filter") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-autocomplete
          :items="selectableEventTypes"
          v-model="selectedEventTypes"
          :label="t('Event type')"
          :placeholder="$t('All types')"
          multiple
          outlined
        ></v-autocomplete>
      </v-list-item>

      <template id="vehicle" v-if="showVehicleFilters">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ t("Vehicle") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-autocomplete
            class="pb-3"
            v-model="formItems.vehicle.category"
            :items="vehicleCategories"
            :label="t('Category')"
            multiple
            outlined
            hide-details
          ></v-autocomplete>
        </v-list-item>
        <v-list-item>
          <v-autocomplete
            class="pb-3"
            v-model="formItems.vehicle.make"
            :items="searchableFields.MAKE"
            :label="t('Make')"
            multiple
            outlined
            hide-details
          ></v-autocomplete>
        </v-list-item>
        <v-list-item>
          <v-autocomplete
            class="pb-3"
            v-model="formItems.vehicle.color"
            :items="colors"
            :label="t('Color')"
            multiple
            outlined
            hide-details
          ></v-autocomplete>
        </v-list-item>
        <v-list-item>
          <v-text-field
            class="pb-3"
            v-model="formItems.lprValue"
            :label="t('License plate')"
            outlined
            hide-details
          ></v-text-field>
        </v-list-item>
      </template>

      <template id="animal" v-if="showAnimalFilters">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ t("Animal") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-autocomplete
            class="pb-3"
            v-model="formItems.animal.category"
            :items="animalCategories"
            :label="t('Category')"
            multiple
            outlined
            hide-details
          ></v-autocomplete>
        </v-list-item>
      </template>

      <template template id="lpr" v-if="showLprFilters">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ t("LPR") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-text-field
            class="pb-3"
            v-model="formItems.lprValue"
            :label="t('License plate')"
            outlined
            hide-details
          ></v-text-field>
        </v-list-item>
      </template>
    </v-list>

    <template v-slot:append>
      <v-list-item>
        <v-list-item-content>
          <v-btn color="primary" :disabled="!dirty" @click="applyFilters">
            {{ t("Apply filter") }}
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script setup>
import { getTranslatedEventType } from "./EventTypeHelpers";
import { store } from "@/store/index";
import { t } from "@eencloud/core-components/src/service/locale";
import { watch, ref, computed, onUnmounted, onBeforeMount, onMounted } from "vue";
import restapi from "@eencloud/core-components/src/service/CMApi";

const props = defineProps({
  open: Boolean,
  eventFilters: Array,
});

const emit = defineEmits(["update", "applyFilters"]);

const { searchableFields } = useSearchableFields();
const { dirty, currentStateSingature, createStateSignature } = useDirtyCheck();
const { animalCategories, vehicleCategories, colors } = useCategories();

const selectedEventTypes = ref([]);

const selectableEventTypes = computed(() => {
  return props.eventFilters.map((eventType) => {
    return {
      text: getTranslatedEventType({ type: eventType }, eventTypes.value),
      value: eventType,
    };
  });
});

const eventTypes = computed(() => {
  const eventTypes = {};
  store.getters.eventTypes.forEach((type) => {
    eventTypes[type.eventType] = type;
  });
  return eventTypes;
});

const formItems = ref({
  lprValue: "",
  animal: {
    category: [],
  },
  vehicle: {
    category: [],
    make: [],
    color: [],
  },
});

watch(
  () => store.getters.eventFilter,
  () => {
    selectedEventTypes.value = [...store.getters.eventFilter];
    currentStateSingature.value = createStateSignature();
  }
);

const showVehicleFilters = computed(() =>
  selectedEventTypes.value.includes("vehicleDetected")
);
const showAnimalFilters = computed(() =>
  selectedEventTypes.value.includes("animalDetected")
);
const showLprFilters = computed(() =>
  selectedEventTypes.value.includes("lprDetected")
);

/**
 * Emit a list of eventTypes with the applicable searchableFields
 */
function applyFilters() {
  const val = composeSearchableFieldsPerEventType();
  store.dispatch("setEventFilter", selectedEventTypes.value);
  currentStateSingature.value = createStateSignature();
  emit("applyFilters", val);
  emit("update", false);
}

function removeEmptyFields(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key].length === 0) {
      delete obj[key];
    }
  });
  return obj;
}

/**
 * For each event type we create a separate searchablefields object to make an api call per event type
 */
function composeSearchableFieldsPerEventType() {
  const eventSearches = [
    {
      types: [],
    },
  ];

  selectedEventTypes.value.forEach((eventType) => {
    switch (eventType) {
      case "vehicleDetected":
        eventSearches.push({
          // allow specifying a license plate when searching for a vehicle
          types: [eventType, "lprDetected"],
          searchableFields: {
            ...removeEmptyFields(formItems.value.vehicle),
            ...(formItems.value.lprValue && {
              lprValue: [formItems.value.lprValue],
            }),
          },
        });
        break;
      case "animalDetected":
        eventSearches.push({
          types: [eventType],
          searchableFields: removeEmptyFields(formItems.value.animal),
        });
        break;
      case "lprDetected":
        eventSearches.push({
          types: [eventType],
          searchableFields: {
            ...(formItems.value.lprValue && {
              lprValue: [formItems.value.lprValue],
            }),
          },
        });
        break;
      default:
        eventSearches[0].types.push(eventType);
        break;
    }
    // remove the empty object in case no searchable event types are selected
    if (eventSearches[0].types.length === 0) {
      eventSearches.shift();
    }
  });
  return eventSearches;
}

function useDirtyCheck() {
  const currentStateSingature = ref("");

  const dirty = computed(() => {
    // compare the state of formItems and selectedEventTypes with the initial state
    return currentStateSingature.value !== createStateSignature();
  });

  onBeforeMount(() => {
    selectedEventTypes.value = [...store.getters.eventFilter];
    if (store.getters.sidebarFormState) {
      const { storedFormItems, stateSignature } =
        store.getters.sidebarFormState;
      if (storedFormItems) {
        formItems.value = structuredClone(storedFormItems);
      }
      if (stateSignature) {
        currentStateSingature.value = stateSignature;
      }
      store.dispatch("setSidebarFormState", null);
    } else {
      currentStateSingature.value = createStateSignature();
    }
  });

  onUnmounted(() => {
    store.dispatch("setSidebarFormState", {
      formItems: { ...formItems.value },
      stateSignature: currentStateSingature.value,
    });
  });

  function createStateSignature() {
    return JSON.stringify({ ...formItems.value, ...selectedEventTypes.value });
  }

  return {
    dirty,
    currentStateSingature,
    createStateSignature,
  };
}

function useCategories() {
  const animalCategories = computed(() => {
    const categories = store.getters.eventTypes.find(
      (type) => type.eventType === "animalDetected"
    );
    console.log(categories);
    if (!categories) {
      return searchableFields.value.ANIMAL_CATEGORY;
    }
    return Object.keys(categories.categoriesTranslated).map((key) => {
      return {
        text: categories.categoriesTranslated[key],
        value: key,
      };
    });
  });

  const vehicleCategories = computed(() => {
    const categories = store.getters.eventTypes.find(
      (type) => type.eventType === "vehicleDetected"
    );
    if (!categories) {
      return searchableFields.value.VEHICLE_CATEGORY;
    }
    return searchableFields.value.VEHICLE_CATEGORY.map((category) => {
      return {
        text: categories.categoriesTranslated[category],
        value: category,
      };
    });
  });

  const colors = computed(() => {
    const categories = store.getters.eventTypes.find(
      (type) => type.eventType === "vehicleDetected"
    );
    if (!categories) {
      return searchableFields.value.COLOR;
    }
    return searchableFields.value.COLOR.map((color) => {
      return {
        text: categories.categoriesTranslated[color],
        value: color,
      };
    });
  });

  return {
    animalCategories,
    vehicleCategories,
    colors,
  };
}

function useSearchableFields() {
  const searchableFields = ref({});

  onBeforeMount(async () => {
    try {
      const res = await restapi.getSearchableFields();
      searchableFields.value = res;
    } catch (error) {
      console.error('failed to fetch searchable fields', error);
    }
  });

  return {
    searchableFields,
  };
}

</script>

<style lang="scss" scoped>
.event-search-sidebar {
  // move the content below the top navigation bar without having to use position:absolute on the drawer
  padding-top: 70px;
  // bring the sidebar on top of the time picker's clock icon
  z-index: 3;
}
</style>
