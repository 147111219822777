<template>
  <v-container fluid class="video-sound pa-0">
    <v-app-bar v-if="showToolbar" dense flat :app="isAppToolbar" :color="toolbarColor || 'white'" class="title--text">
      <v-container v-if="isDirty && !loading" class="my-2 align">
        <v-row class="xs8 offset-xs2">
          <v-spacer></v-spacer>
            <v-btn @click="cancelChanges">{{ $t("Cancel") }}</v-btn>
            <v-btn class="ml-2" color="primary" @click="saveChanges">{{ $t("Save") }}</v-btn>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-container v-if="camera" class="pa-0 align">
      <v-container class="pa-0" fluid>
        <v-row>
          <v-col cols="10" class="hidden-sm-and-up">
            <v-btn text class="pa-0" @click="$emit('allCameras')">
              <v-icon size="18" left>
                fa-arrow-left
              </v-icon>
              <p class="mb-0">{{ $t("Video & Sound") }}</p>
            </v-btn>
          </v-col>
          <v-col cols="2" class="hidden-sm-and-up">
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" dark icon>
                  <v-icon size="18" color="black">
                    fa-ellipsis-v
                  </v-icon>
                </v-btn>
              </template>
              <v-list rounded dense class="px-4">
                <v-list-item
                  v-for="(menu, i) in menus"
                  :key="i"
                  @click="$router.push({ path: `/settings/cameras/${camera.cameraId}/${menu.endpoint}` })"
                >
                  <v-list-item-title>{{ menu.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
      <v-row>
        <v-col cols="12" sm="10" offset-sm="1" class="pt-0">
          <p class="text-uppercase pl-0 mb-2">{{ $t("Video") }}</p>
          <v-card v-if="hasRecordingCapabilities">
            <v-col class="px-0 py-0">
              <v-list rounded dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-row>
                      <v-col class="body-2 pt-2 py-0">{{ $t("Resolution") }}</v-col>
                      <v-col class="py-0">
                        <v-select
                          v-model="resolution"
                          :items="resolutions"
                          solo
                          dense
                          flat
                          hide-details
                          background-color="shadow"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider class="my-1"></v-divider>
              <v-list rounded dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-row>
                      <v-col class="body-2 pt-2 py-0">{{ $t("Frames per seconds") }}</v-col>
                      <v-col class="py-0">
                        <v-select
                          v-model="framerate"
                          :items="framerates"
                          :outline="!isFrameRateCorrect"
                          :solo="isFrameRateCorrect"
                          :dense="isFrameRateCorrect"
                          :flat="isFrameRateCorrect"
                          :error-messages="getFrameRateErrorMessages"
                          :background-color="isFrameRateCorrect ? 'shadow' : 'undefined'"
                          hide-details
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider class="mb-1"></v-divider>
              <v-list rounded dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-card flat>
                      <v-card-text class="pa-0">
                        <v-row>
                          <v-col class="pt-2 py-0">{{ $t("Maximum bandwidth") }}</v-col>
                          <v-col class="py-0">
                            <p class="text-right ma-0">
                              {{ maxBitRate }} kb/s
                              <v-btn icon :ripple="false" @click="showMaxBandwidth = !showMaxBandwidth">
                                <v-icon v-if="!showMaxBandwidth" small>fa-chevron-down</v-icon>
                                <v-icon v-else small>fa-chevron-up</v-icon>
                              </v-btn>
                            </p>
                          </v-col>
                        </v-row>
                        <v-container v-if="showMaxBandwidth" class="pa-0" fluid>
                          <v-row>
                            <v-col cols="12">
                              <p>
                                {{
                                  $t(
                                    "The maximum amount of bandwidth used to record footage into the cloud. Useful for internet connections with limited capacity. A lower bandwidth will lower the footage quality, but will have no effect on the frame rate or the footage resolution."
                                  )
                                }}
                              </p>
                            </v-col>
                            <v-col cols="12">
                              <v-slider
                                v-model="maxBitRate"
                                min="128"
                                max="4096"
                                step="128"
                                label="kb/s"
                                inverse-label
                                thumb-label="always"
                                always-dirty
                                ticks="always"
                                class="mt-12"
                              ></v-slider>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-card>
          <p v-else>{{ $t("Cannot show or change recording settings") }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="canRecordAudio && microphoneEnabled !== null" cols="12" sm="10" offset-sm="1" class="pt-0">
          <p class="text-uppercase mb-2">{{ $t("Sound") }}</p>
          <v-card>
            <v-container class="py-0" fluid>
              <v-row class="pl-2 pr-5">
                <v-col cols="11">
                  <div v-if="microphoneEnabled" class="body-2 ma-0">{{ $t("Microphone enabled") }}</div>
                  <p v-else class="body-2 ma-0">{{ $t("Microphone disabled") }}</p>
                  <p class="mb-0">
                    {{
                      $t(
                        "By turning on your microphone all sound will be recorded and captured. Please consider the local law when recording sound."
                      )
                    }}
                  </p>
                </v-col>
                <v-col cols="1" :pl-4="$vuetify.breakpoint.mdAndUp">
                  <v-switch class="mt-1" hide-details dense v-model="microphoneEnabled" color="accent"></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="showAdvancedVideoSettings" cols="12" sm="10" offset-sm="1" class="pt-0">
          <p class="text-uppercase mb-2">{{ $t("Advanced video settings") }}</p>
          <v-card>
            <template v-if="videoSettings && imageCapabilities && imageCapabilities.rotation">
              <v-card flat>
                <v-card-text class="py-0">
                  <v-row>
                    <v-col class="my-4 py-0">{{ $t("Rotate image") }}</v-col>
                    <v-col class="py-2">
                      <p class="text-right ma-0">
                        {{ rotation }}
                        <v-btn icon :ripple="false" @click="showRotateImage = !showRotateImage">
                          <v-icon v-if="!showRotateImage" small>fa-chevron-down</v-icon>
                          <v-icon v-else small>fa-chevron-up</v-icon>
                        </v-btn>
                      </p>
                    </v-col>
                  </v-row>
                  <v-container v-if="showRotateImage" class="pa-0" fluid>
                    <v-row>
                      <v-col cols="6">
                        <p>
                          {{
                            $t(
                              "Rotate the image, for example when the camera hangs upside down the image can be rotated."
                            )
                          }}
                        </p>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="rotation"
                          :items="rotationOptions"
                          solo
                          dense
                          flat
                          hide-details
                          background-color="shadow"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
              <v-divider class="my-1"></v-divider>
            </template>
            <template v-if="videoSettings && imageCapabilities && imageCapabilities.exposure">
              <v-card flat>
                <v-card-text class="py-0">
                  <v-row>
                    <v-col class="my-4 py-0">{{ $t("Anti-flickering") }}</v-col>
                    <v-col class="py-2">
                      <p class="text-right text-capitalize ma-0">
                        {{ exposure }}
                        <v-btn icon :ripple="false" @click="showAntiFlickering = !showAntiFlickering">
                          <v-icon v-if="!showAntiFlickering" small>fa-chevron-down</v-icon>
                          <v-icon v-else small>fa-chevron-up</v-icon>
                        </v-btn>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row v-if="showAntiFlickering">
                    <v-col cols="6">
                      <p>
                        {{
                          $t(
                            "This setting is avoiding the flickering of (for example) computer screens or lights in the camera image. It might cause some inconsistent images and is related to the kind of power source that is used in different countries."
                          )
                        }}
                      </p>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        v-model="exposure"
                        :items="exposureOptions"
                        solo
                        dense
                        flat
                        hide-details
                        background-color="shadow"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-divider class="my-1"></v-divider>
            </template>
            <template v-if="videoSettings && imageCapabilities && imageCapabilities.backlight">
              <v-card flat>
                <v-card-text class="py-0">
                  <v-row>
                    <v-col class="my-4 py-0">{{ $t("Backlight compensation") }}</v-col>
                    <v-col class="py-2">
                      <p class="text-right ma-0">
                        {{ backlightDisplay }}
                        <v-btn icon :ripple="false" @click="showBacklight = !showBacklight">
                          <v-icon v-if="!showBacklight" small>fa-chevron-down</v-icon>
                          <v-icon v-else small>fa-chevron-up</v-icon>
                        </v-btn>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row v-if="showBacklight">
                    <v-col cols="6">
                      <p>{{ $t("BLC - Backlight Compensation, emphasizes dark parts of the camera image.") }}</p>
                      <p>
                        {{
                          $t("HLC - Highlight Compensation, compensations shiny objects which can disturb the image.")
                        }}
                      </p>
                      <p>
                        {{
                          $t(
                            "WDR - Wide Dynamic Range, compensates for large differences between light and dark parts in the image."
                          )
                        }}
                      </p>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        v-model="backlight"
                        :items="backlightOptions"
                        solo
                        dense
                        flat
                        hide-details
                        background-color="shadow"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-divider class="my-1"></v-divider>
            </template>
            <template v-if="videoSettings && imageCapabilities && imageCapabilities.manualNightview">
              <v-card flat>
                <v-card-text class="py-0">
                  <v-row>
                    <v-col class="my-4 py-0">{{ $t("Manual night view") }}</v-col>
                    <v-col class="py-2">
                      <p class="text-right ma-0">
                        {{ manualNightview }}
                        <v-btn icon :ripple="false" @click="showManualNightview = !showManualNightview">
                          <v-icon v-if="!showManualNightview" small>fa-chevron-down</v-icon>
                          <v-icon v-else small>fa-chevron-up</v-icon>
                        </v-btn>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row v-if="showManualNightview">
                    <v-col cols="6">
                      <p></p>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        v-model="manualNightview"
                        :items="manualNightviewOptions"
                        solo
                        dense
                        flat
                        hide-details
                        background-color="shadow"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-divider class="my-1"></v-divider>
            </template>
            <template v-if="videoSettings && imageCapabilities && imageCapabilities.coloredNightview">
              <v-card flat>
                <v-card-text class="py-0">
                  <v-row>
                    <v-col class="my-4 py-0">{{ $t("Colored night view") }}</v-col>
                    <v-col class="py-2">
                      <p class="text-right ma-0">
                        {{ coloredNightview }}
                        <v-btn icon :ripple="false" @click="showColoredNightview = !showColoredNightview">
                          <v-icon v-if="!showColoredNightview" small>fa-chevron-down</v-icon>
                          <v-icon v-else small>fa-chevron-up</v-icon>
                        </v-btn>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row v-if="showColoredNightview">
                    <v-col cols="11">
                      <p>{{ $t("Colored night view will make your night more colorful.") }}</p>
                    </v-col>
                    <v-col cols="1" :pl-4="$vuetify.breakpoint.mdAndUp">
                      <v-switch hide-details dense v-model="coloredNightview" color="accent"></v-switch>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-divider class="my-1"></v-divider>
            </template>
            <template v-if="false && videoSettings && imageCapabilities && imageCapabilities.brightness">
              <v-card></v-card>
              <v-divider class="my-1"></v-divider>
            </template>
            <template v-if="false && videoSettings && imageCapabilities && imageCapabilities.shutterspeed">
              <v-card></v-card>
              <v-divider class="my-1"></v-divider>
            </template>
            <template v-if="false && videoSettings && imageCapabilities && imageCapabilities.focus">
              <v-card></v-card>
              <v-divider class="my-1"></v-divider>
            </template>
            <v-list rounded dense v-if="rights && !rights.demoUser">
              <v-list-item flat>
                <v-btn small text color="primary" @click="restoreDefaultsWarning = true">{{
                  $t("Restore defaults")
                }}</v-btn>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="loading" persistent width="300" height="300">
      <v-card>
        <v-card-text>
          <v-container class="fill-height">
            <v-row align="center">
              <v-col cols="12" class="text-center">
                <een-spinner :size="50"></een-spinner>
              </v-col>
              <v-col cols="12" class="text-center">{{ $t("Connecting to camera") }}</v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="restoreDefaultsWarning" hide-overlay max-width="500">
      <v-card>
        <v-card-title class="headline">{{ $t("Please confirm") }}</v-card-title>
        <v-card-text>{{ $t("Are you sure you want to restore the camera default settings?") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="restoreDefaultVideoSettings">{{ $t("Confirm") }}</v-btn>
          <v-btn depressed @click="restoreDefaultsWarning = false">{{ $t("Cancel") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { store } from "@/store";
import warningForCameraSettingsChangesMixin from '@eencloud/core-components/src/service/warningForCameraSettingsChangesMixin';

export default {
  name: "VideoSound",
  props: ["camera", "rights", "showToolbar", "appToolbar", "toolbarColor", "menus", "desktop"],
  mixins: [warningForCameraSettingsChangesMixin],
  data() {
    return {
      isDirty: false,
      initValues: {},
      microphoneEnabled: null,
      cameraMode: null,
      footageCapabilities: null,
      footageSettings: null,
      imageCapabilities: null,
      videoSettings: null,
      restoreDefaultsWarning: false,
      showAdvancedSettings: true,
      showMaxBandwidth: false,
      showRotateImage: false,
      showAntiFlickering: false,
      showBacklight: false,
      showManualNightview: false,
      showColoredNightview: false,
      loading: false,
    };
  },
  computed: {
    isAppToolbar() {
      if (this.appToolbar === undefined || this.appToolbar === null) return true;
      return !!this.appToolbar;
    },
    cameraId() {
      return this.camera ? this.camera.cameraId : null;
    },
    online() {
      return this.status ? this.status.online : null;
    },
    status() {
      return this.desktop ? this.desktop.status : this.camera ? this.camera.status : null;
    },
    cameraCapabilities() {
      return this.desktop ? this.desktop.cameraCapabilities : this.camera ? this.camera.cameraCapabilities : null;
    },
    canRecordAudio() {
      return this.cameraCapabilities ? this.cameraCapabilities.recordAudio : null;
    },
    hasRecordingCapabilities() {
      return (
        this.footageCapabilities &&
        this.footageCapabilities.hasOwnProperty("rec") &&
        this.footageCapabilities.rec !== null
      );
    },
    resolutions() {
      return this.footageCapabilities && this.footageCapabilities.rec
        ? Object.keys(this.footageCapabilities.rec).sort(function (a, b) {
            return parseInt(a) - parseInt(b);
          })
        : [];
    },
    framerates() {
      const rec = this.resolution && this.footageCapabilities && this.footageCapabilities.rec[this.resolution]
      return rec ? rec.framerates : [ 1 ]
    },
    minFramerate() {
      const rec = this.resolution && this.footageCapabilities && this.footageCapabilities.rec[this.resolution]
      return rec ? Math.min(...rec.framerates) : 1
    },
    maxFramerate() {
      const rec = this.resolution && this.footageCapabilities && this.footageCapabilities.rec[this.resolution]
      return rec ? Math.max(...rec.framerates) : 1
    },
    isFrameRateCorrect() {
      if (this.framerate) {
        return this.minFramerate <= this.framerate && this.framerate <= this.maxFramerate;
      } else {
        return true;
      }
    },
    getFrameRateErrorMessages() {
      return this.isFrameRateCorrect ? "" : this.$t("Cannot be empty");
    },
    maximumBandwidth() {
      if (this.maxBitRate < 1024) {
        return `${this.maxBitRate} kb/s`;
      } else {
        const bandwidth = this.maxBitRate / 1024;
        return `${bandwidth} Mbit/s`;
      }
    },
    resolution: {
      get() {
        return this.footageSettings ? this.footageSettings.rec.video.resolution : null;
      },
      set: function (resolution) {
        this.footageSettings.rec.video.resolution = resolution;
        this.checkIfDirty();
      },
    },
    framerate: {
      get() {
        return this.footageSettings ? this.footageSettings.rec.video.framerate : null;
      },
      set: function (framerate) {
        this.footageSettings.rec.video.framerate = framerate;
        this.checkIfDirty();
      },
    },
    maxBitRate: {
      get() {
        return this.footageSettings ? this.footageSettings.rec.video.maxBitRate : null;
      },
      set: function (maxBitRate) {
        this.footageSettings.rec.video.maxBitRate = maxBitRate;
        this.checkIfDirty();
      },
    },
    rotation: {
      get() {
        return this.videoSettings && this.videoSettings.rotation !== null
          ? parseInt(this.videoSettings.rotation)
          : null;
      },
      set: function (rotation) {
        this.videoSettings.rotation = parseInt(rotation);
        this.checkIfDirty();
      },
    },
    rotationOptions() {
      return this.imageCapabilities
        ? this.imageCapabilities.rotationOptions.map(function (o) {
            return { value: o, text: o.toString() };
          })
        : null;
    },
    exposure: {
      get() {
        return this.videoSettings ? this.videoSettings.exposure : null;
      },
      set: function (exposure) {
        this.videoSettings.exposure = exposure;
        this.checkIfDirty();
      },
    },
    exposureOptions() {
      return this.imageCapabilities
        ? this.imageCapabilities.exposureOptions.map(function (o) {
            return { value: o, text: o.charAt(0).toUpperCase() + o.substr(1) };
          })
        : null;
    },
    backlight: {
      get() {
        return this.videoSettings ? this.videoSettings.backlight : null;
      },
      set: function (backlight) {
        this.videoSettings.backlight = backlight;
        this.checkIfDirty();
      },
    },
    backlightDisplay() {
      const bl = this.videoSettings.backlight;
      return this.videoSettings ? bl.toUpperCase() + (bl === "off" ? " - no compensation" : "") : null;
    },
    backlightOptions() {
      return this.imageCapabilities
        ? this.imageCapabilities.backlightOptions.map(function (o) {
            return {
              value: o,
              text: o.toUpperCase() + (o === "off" ? " - no compensation" : ""),
            };
          })
        : null;
    },
    manualNightview: {
      get() {
        return this.videoSettings ? this.videoSettings.manualNightview : null;
      },
      set: function (manualNightview) {
        this.videoSettings.manualNightview = manualNightview;
        this.checkIfDirty();
      },
    },
    manualNightviewOptions() {
      return this.imageCapabilities
        ? this.imageCapabilities.manualNightviewOptions.map(function (o) {
            return { value: o, text: o };
          })
        : null;
    },
    coloredNightview: {
      get() {
        return this.videoSettings ? this.videoSettings.coloredNightview : null;
      },
      set: function (coloredNightview) {
        this.videoSettings.coloredNightview = coloredNightview;
        this.checkIfDirty();
      },
    },
    brightness: {
      get() {
        return this.videoSettings ? this.videoSettings.brightness : null;
      },
      set: function (brightness) {
        this.videoSettings.brightness = brightness;
        this.checkIfDirty();
      },
    },
    shutterspeed: {
      get() {
        return this.videoSettings ? this.videoSettings.shutterspeed : null;
      },
      set: function (shutterspeed) {
        this.videoSettings.shutterspeed = shutterspeed;
        this.checkIfDirty();
      },
    },
    focus: {
      get() {
        return this.videoSettings ? this.videoSettings.focus : null;
      },
      set: function (focus) {
        this.videoSettings.focus = focus;
        this.checkIfDirty();
      },
    },
    showAdvancedVideoSettings() {
      return this.videoSettings && this.imageCapabilities
        ? this.imageCapabilities.backlight ||
            this.imageCapabilities.brightness ||
            this.imageCapabilities.exposure ||
            this.imageCapabilities.focus ||
            this.imageCapabilities.manualNightview ||
            this.imageCapabilities.rotation ||
            this.imageCapabilities.shutterspeed
        : null;
    },
  },
  watch: {
    camera: function (newVal, oldVal) {
      if (
        (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) ||
        (oldVal !== null && newVal === null)
      ) {
        this.isDirty = false;
      }
      if (newVal) {
        this.getCameraInfo();
      }
    },
    online: function (newVal, oldVal) {
      if (newVal) {
        const that = this;
        that.restapi
          .getCameraVideoSettings(that.cameraId)
          .then(function (settings) {
            that.videoSettings = settings;
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    },
    canRecordAudio: function (newVal, oldVal) {
      if (this.canRecordAudio) {
        const that = this;
        that.restapi
          .getCameraMicrophoneEnabled(that.cameraId)
          .then(function (enabled) {
            that.microphoneEnabled = enabled;
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    },
    microphoneEnabled: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.microphoneEnabled = this.microphoneEnabled;
      }
      if (oldVal !== null) {
        this.checkIfDirty();
      }
    },
    resolution: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.resolution = this.resolution;
      }
    },
    framerate: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.framerate = this.framerate;
      }
    },
    rotation: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.rotation = this.rotation;
      }
    },
    exposure: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.exposure = this.exposure;
      }
    },
    backlight: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.backlight = this.backlight;
      }
    },
    manualNightview: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.manualNightview = this.manualNightview;
      }
    },
    coloredNightview: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.coloredNightview = this.coloredNightview;
      }
    },
    maxBitRate: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.maxBitRate = this.maxBitRate;
      }
    },
    brightness: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.brightness = this.brightness;
      }
    },
    shutterspeed: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.shutterspeed = this.shutterspeed;
      }
    },
    focus: function (newVal, oldVal) {
      if (newVal !== null && typeof newVal !== "undefined" && (oldVal === null || typeof oldVal === "undefined")) {
        this.initValues.focus = this.focus;
      }
    },
    "$route.query"() {
      if (this.cameraId) {
        this.getCameraInfo();
      }
    },
  },
  mounted() {
    if (this.cameraId) {
      this.getCameraInfo();
    }
  },
  methods: {
    getCameraInfo() {
      this.cameraMode = null;
      this.microphoneEnabled = null;
      this.footageCapabilities = null;
      this.footageSettings = null;
      this.imageCapabilities = null;
      this.videoSettings = null;
      const that = this;
      that.loading = true;
      return that.restapi
        .getCameraMode(that.cameraId)
        .then(function (mode) {
          that.cameraMode = mode;
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          if (that.canRecordAudio) {
            return that.restapi
              .getCameraMicrophoneEnabled(that.cameraId)
              .then(function (enabled) {
                that.microphoneEnabled = enabled;
              })
              .catch(function (error) {
                console.error(error);
              })
              .then(function () {
                return true;
              });
          } else {
            return true;
          }
        })
        .then(function () {
          return that.restapi
            .getCameraFootageCapabilities(that.cameraId)
            .then(function (capabilities) {
              // capabilities.rec = capabilities.live
              that.footageCapabilities = capabilities;
            })
            .catch(function (error) {
              console.error(error);
            })
            .then(function () {
              return true;
            });
        })
        .then(function () {
          return that.restapi
            .getCameraFootageSettings(that.cameraId)
            .then(function (settings) {
              that.footageSettings = settings;
            })
            .catch(function (error) {
              console.error(error);
            })
            .then(function () {
              return true;
            });
        })
        .then(function () {
          return that.restapi
            .getCameraImageCapabilities(that.cameraId)
            .then(function (capabilities) {
              that.imageCapabilities = capabilities;
            })
            .catch(function (error) {
              console.error(error);
            })
            .then(function () {
              return true;
            });
        })
        .then(function () {
          if (that.online) {
            return that.restapi
              .getCameraVideoSettings(that.cameraId)
              .then(function (settings) {
                that.videoSettings = settings;
              })
              .catch(function (error) {
                that.loading = false;
                console.error(error);
              })
              .then(function () {
                return true;
              });
          } else {
            return true;
          }
        })
        .then(function () {
          that.loading = false;
          that.checkIfDirty();
        })
        .catch(function (error) {
          that.loading = false;
          console.error(error);
        });
    },
    restoreDefaultVideoSettings() {
      this.restoreDefaultsWarning = false;
      const that = this;
      this.restapi
        .deleteCameraVideoSettings(this.cameraId)
        .then(function () {
          that.restapi
            .getCameraVideoSettings(that.cameraId)
            .then(function (settings) {
              that.videoSettings = settings;
            })
            .catch(function (error) {
              console.error(error);
            });
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    checkIfDirty() {
      if (!this.initValues.hasOwnProperty("resolution")) return null;
      this.isDirty =
        this.initValues.resolution !== this.resolution ||
        this.initValues.framerate !== this.framerate ||
        this.initValues.maxBitRate !== this.maxBitRate || 
        (this.canRecordAudio && this.initValues.microphoneEnabled !== this.microphoneEnabled) ||
        (this.imageCapabilities.rotation && this.initValues.rotation !== undefined && parseInt(this.initValues.rotation) !== parseInt(this.rotation)) ||
        (this.imageCapabilities.exposure && this.initValues.exposure !== undefined && this.initValues.exposure !== this.exposure) ||
        (this.imageCapabilities.backlight && this.initValues.backlight !== undefined && this.initValues.backlight!== this.backlight) ||
        (this.imageCapabilities.manualNightview && this.initValues.manualNightview !== undefined && this.initValues.manualNightview !== this.manualNightview) ||
        (this.imageCapabilities.coloredNightview && this.initValues.coloredNightview !== undefined && this.initValues.coloredNightview !== this.coloredNightview) ||
        (this.imageCapabilities.brightness && this.initValues.brightness !== undefined && this.initValues.brightness !== this.brightness) ||
        (this.imageCapabilities.shutterspeed && this.initValues.shutterspeed !== undefined && this.initValues.shutterspeed !== this.shutterspeed) ||
        (this.imageCapabilities.focus && this.initValues.focus !== undefined && this.initValues.focus !== this.focus);
    },
    cancelChanges() {
      this.resolution = this.initValues.resolution;
      this.framerate = this.initValues.framerate;
      this.maxBitRate = this.initValues.maxBitRate;
      this.microphoneEnabled = this.initValues.microphoneEnabled;
      if(this.initValues.rotation !== undefined) {
        this.rotation = this.initValues.rotation;
      }
      if(this.initValues.exposure !== undefined) {
        this.exposure = this.initValues.exposure;
      }
      if(this.initValues.backlight !== undefined) {
        this.backlight = this.initValues.backlight;
      }
      if(this.initValues.manualNightview !== undefined) {
        this.manualNightview = this.initValues.manualNightview;
      }
      if(this.initValues.coloredNightview !== undefined) {
        this.coloredNightview = this.initValues.coloredNightview;
      }
      if(this.initValues.brightness !== undefined) {
        this.brightness = this.initValues.brightness;
      }
      if(this.initValues.shutterspeed !== undefined) {
        this.shutterspeed = this.initValues.shutterspeed;
      }
      if(this.initValues.focus !== undefined) {
        this.focus = this.initValues.focus;
      }
    },
    saveChanges() {
      if (this.rights && this.rights.demoUser) {
        return this.$store.dispatch("demoMessage", this.$t("As a demo user you cannot make any changes."))
      }
      if (!this.isFrameRateCorrect) {
        store.dispatch("toastMessage", {
          showing: true,
          text: this.$t("Frame rate can't be empty"),
          timeout: -1,
          color: "error",
          support: true
        });
        return true;
      }
      let hasErrors = false;
      const that = this;
      const promise = new Promise(function (resolve, reject) {
        resolve("Success!");
      });
      promise
        .then(function () {
          that.loading = true;
          if (
            that.initValues.resolution !== that.resolution ||
            that.initValues.framerate !== that.framerate ||
            that.initValues.maxBitRate !== that.maxBitRate
          ) {
            return that.restapi
              .setCameraFootageSettings(that.cameraId, {
                rec: {
                  video: {
                    resolution: that.resolution,
                    framerate: that.framerate,
                    maxBitRate: that.maxBitRate,
                  },
                },
              })
              .then(function () {
                that.initValues.resolution = that.resolution;
                that.initValues.framerate = that.framerate;
                that.initValues.maxBitRate = that.maxBitRate;
                that.checkIfDirty();
              })
              .catch(function (error) {
                console.error(error);
                hasErrors = true;
              })
              .then(function () {
                return hasErrors;
              });
          } else {
            return hasErrors;
          }
        })
        .then(function () {
          if (that.microphoneEnabled !== null && that.initValues.microphoneEnabled !== that.microphoneEnabled) {
            return that.restapi
              .setCameraMicrophoneEnabled(that.cameraId, that.microphoneEnabled)
              .then(function () {
                that.initValues.microphoneEnabled = that.microphoneEnabled;
                that.checkIfDirty();
              })
              .catch(function (error) {
                console.error(error);
                hasErrors = true;
              })
              .then(function () {
                return hasErrors;
              });
          } else {
            return hasErrors;
          }
        })
        .then(function () {
          const settings = {};
          if (that.imageCapabilities.rotation && parseInt(that.initValues.rotation) !== parseInt(that.rotation))
            settings.rotation = that.rotation;
          if (that.imageCapabilities.exposure && that.initValues.exposure !== that.exposure)
            settings.exposure = that.exposure;
          if (that.imageCapabilities.backlight && that.initValues.backlight !== that.backlight)
            settings.backlight = that.backlight;
          if (that.imageCapabilities.manualNightview && that.initValues.manualNightview !== that.manualNightview)
            settings.manualNightview = that.manualNightview;
          if (that.imageCapabilities.coloredNightview && that.initValues.coloredNightview !== that.coloredNightview)
            settings.coloredNightview = that.coloredNightview;
          if (that.imageCapabilities.brightness && that.initValues.brightness !== that.brightness)
            settings.brightness = that.brightness;
          if (that.imageCapabilities.shutterspeed && that.initValues.shutterspeed !== that.shutterspeed)
            settings.shutterspeed = that.shutterspeed;
          if (that.imageCapabilities.focus && that.initValues.focus !== that.focus) settings.focus = that.focus;
          if (JSON.stringify(settings) !== JSON.stringify({})) {
            return that.restapi
              .setCameraVideoSettings(that.cameraId, settings)
              .then(function () {
                if (that.imageCapabilities.rotation) that.initValues.rotation = that.rotation;
                if (that.imageCapabilities.exposure) that.initValues.exposure = that.exposure;
                if (that.imageCapabilities.backlight) that.initValues.backlight = that.backlight;
                if (that.imageCapabilities.manualNightview) that.initValues.manualNightview = that.manualNightview;
                if (that.imageCapabilities.coloredNightview) that.initValues.coloredNightview = that.coloredNightview;
                if (that.imageCapabilities.brightness) that.initValues.brightness = that.brightness;
                if (that.imageCapabilities.shutterspeed) that.initValues.shutterspeed = that.shutterspeed;
                if (that.imageCapabilities.focus) that.initValues.focus = that.focus;
                return hasErrors;
              })
              .catch(function (error) {
                console.error(error);
              })
              .then(function () {
                return hasErrors;
              });
          } else {
            return hasErrors;
          }
        })
        .then(function () {
          that.checkIfDirty();
          that.loading = false;
          if (hasErrors === false) {
            store.dispatch("toastMessage", {
              showing: true,
              text: that.$t("Your changes were successfully saved."),
              timeout: 4000,
              color: "primary",
            });
          } else {
            store.dispatch("toastMessage", {
              showing: true,
              text: that.$t("An error occurred while saving your changes."),
              timeout: -1,
              color: "error",
              support: true
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/settings";
</style>
