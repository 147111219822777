<template>
  <div class="partner-profile edit-mode">
    <div class="head">
      <p>{{ $t("To add or remove a permission for this role use the corresponding switch.") }}</p>
    </div>
    <v-btn :disabled="!isDirty" @click="saveNewPermissions" color="primary">{{ $t("Save Changes") }}</v-btn>
    <div>
      <v-card class="mt-4">
        <div class="right" v-for="(value, name) in newPermissions" :class="permissionStyle(name)" :key="name + 'div'">
          <v-row class="px-10" align="center" v-if="invalidPermission(name)" :key="name">
            <v-col>
              <p class="mb-0 font-weight-bold grey--text text--darken-4">{{ $t(name) }}</p>
              <p class="mb-0 grey--text text--darken-4">{{ $t(name + "Description") }}</p>
            </v-col>
            <v-col class="ma-0 pa-0 tooltip" cols="auto">
              <span v-if="name == 'LiveCamera' || name == 'ZonesView' || name == 'CameraView'" class="tooltiptext">{{
                $t("Every user must have these permissions.")
              }}</span>
              <v-switch
                color="primary"
                v-model="newPermissions[name]"
                @change="permissionUpdate(name, $event)"
                dense
                :disabled="isdisabled(name)"
              >
              </v-switch>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { store } from "@/store";
import { getMessageFromError } from "@eencloud/core-components/src/service/errors";

const hash = require("object-hash");

export default {
  name: "UserRights",
  props: ["rights"],
  data() {
    return {
      permissions: null,
      newPermissions: null,
      isTrue: true,
      isFalse: false,
      implicitPermission: {
        LiveCamera: true,
        CameraView: true,
        ZonesView: true,
      },
      invalidPermissions: [
        "cameraIds",
        "zoneIds",
        "lockRecording",
        "addSequence",
        "deleteSequence",
        "editSequence",
        "addCameraGroup",
        "blockUser",
        "cameraExposureControl",
        "cameraIllumination",
        "cameraPrivacyButton",
        "deleteCameraGroup",
        "editCameraGroup",
        "editSchedule",
        "localExceptions",
        "logFiles",
        "motionDetection",
        "receiveCameraAlerts",
        "storageUsage",
        "audioPIR",
        "edgeStorageManagement",
        "addPnpCamera",
      ],
    };
  },
  async mounted() {
    this.permissions = await this.restapi.getSpecificUserRights(this.$route.params.userId);
    let keyValues = Object.entries(this.permissions);
    keyValues.splice(0, 0, ["LiveCamera", true]);
    keyValues.splice(9, 0, ["ZonesView", true]);
    keyValues.splice(19, 0, ["CameraView", true]);

    //small hack to place viewUserSettings above addUser, just like zones and cameras:
    const viewUserSettingsIndex = keyValues.findIndex((e) => e[0] === "viewUserSettings");
    const addUserIndex = keyValues.findIndex((e) => e[0] === "addUser");
    const viewUserSettingsValue = keyValues[viewUserSettingsIndex][1];
    keyValues.splice(viewUserSettingsIndex, 1);
    keyValues.splice(addUserIndex, 0, ["viewUserSettings", viewUserSettingsValue]);

    this.permissions = Object.fromEntries(keyValues);
    this.newPermissions = _.cloneDeep(this.permissions);
  },
  methods: {
    permissionUpdate(name, value) {
      this.newPermissions[name] = value;

      if (name == "addCameraManually") {
        this.newPermissions.addPnpCamera = value;
      }
      if (name === "viewUserSettings" && !value) {
        this.newPermissions.addUser = false;
        this.newPermissions.editUser = false;
        this.newPermissions.deleteUser = false;
      }

      if (name === "addUser" || name === "editUser" || (name === "deleteUser" && value)) {
        this.newPermissions.viewUserSettings = true;
      }

      if (name === "playback" && !value) {
        this.newPermissions.deleteRecording = false;
        this.newPermissions.export = false;
      }

      if (name === "deleteRecording" || (name === "export" && value)) {
        this.newPermissions.playback = true;
      }
      if (name === "editCamera") {
        this.newPermissions.editSchedule = value;
        this.newPermissions.receiveCameraAlerts = value;
      }
    },

    invalidPermission(permission) {
      if (this.invalidPermissions.includes(permission)) return false;
      return true;
    },
    isdisabled(name) {
      if (this.newPermissions.demoUser) {
        if (name == "demoUser") return false;
        return true;
      } else if (name == "LiveCamera" || name == "ZonesView" || name == "CameraView") {
        return true;
      } else {
        return false;
      }
    },
    permissionStyle(name) {
      if (name == "ptz" || name == "export" || name == "deleteZone" || name == "deleteCamera" || name == "demoUser")
        return "spaced-bottom";
      return "";
    },
    async saveNewPermissions() {
      if (this.rights && this.rights.demoUser) {
        return this.$store.dispatch("demoMessage");
      }
      let that = this;
      let zones = (await this.restapi.getSpecificUserRights(this.$route.params.userId)).zoneIds
      this.newPermissions.zoneIds = zones
      this.restapi
        .setSpecificUserRights(this.$route.params.userId, this.newPermissions)
        .then(() => {
          store.dispatch("toastMessage", {
            text: that.$t("Permissions have been saved successfully"),
            color: "primary",
            showing: true,
            timeout: 4000,
          });
        })
        .catch((error) => {
          store.dispatch("toastMessage", {
            text: getMessageFromError(error, "SET_USER_RIGHTS"),
            color: "error",
            showing: true,
            timeout: -1,
            support: true,
          });
        });
      this.permissions = _.cloneDeep(this.newPermissions);
    },
  },
  computed: {
    isDirty() {
      return hash(this.newPermissions) !== hash(this.permissions);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/main";

.partner-profile {
  max-width: 320px;
  width: 320px;
  margin: 10px;
  h2 {
    color: $primary;
    text-transform: uppercase;
    font-size: 14px;
  }

  &.edit-mode {
    position: relative;
    padding-bottom: 40px;
    max-width: 900px;
    width: 900px;
    margin: 0 auto;
  }
}

.spaced-bottom {
  border-bottom: 8px solid #eeeeee;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  display: none;
  cursor: pointer;
  width: 220px;
  background-color: rgba(0, 0, 0, 0.39);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  display: inherit;
}
</style>
