import _ from "lodash";
import { getTimeStamp } from "@eencloud/core-components/src/service/time";

const initialState = {
  cameraFilter: [],
  zoneFilter: [],
  dateFilter: getTimeStamp().slice(0, 10),
  startTimeFilter: getTimeStamp(60).slice(12, 17),
  endTimeFilter: getTimeStamp().slice(12, 17),
  eventFilter: ["AnalyticsObject", "AnalyticsPerson"],
  eventSpecificSearchableFields: [],
  timeSet: false,
  sidebarForm: null,
  sidebarStateSignature: null,
};

const state = _.cloneDeep(initialState);
state.eventTypes = [];

const getters = {
  eventTypes: (state) => {
    return state.eventTypes;
  },
  cameraFilter: (state) => {
    return state.cameraFilter;
  },
  zoneFilter: (state) => {
    return state.zoneFilter;
  },
  eventSpecificSearchableFields: (state) => {
    return state.eventSpecificSearchableFields;
  },
  dateFilter: (state) => {
    return state.dateFilter;
  },
  startTimeFilter: (state) => {
    return state.startTimeFilter;
  },
  endTimeFilter: (state) => {
    return state.endTimeFilter;
  },
  eventFilter: (state) => {
    return state.eventFilter;
  },
  timeSet: (state) => {
    return state.timeSet;
  },
  sidebarFormState: (state) => {
    return {
      storedFormItems: state.sidebarForm,
      stateSignature: state.sidebarStateSignature,
    };
  },
};

const mutations = {
  SET_EVENT_TYPES: (state, types) => {
    state.eventTypes = types;
  },
  SET_CAMERA_FILTER: (state, cameras) => {
    state.cameraFilter = cameras;
  },
  SET_ZONE_FILTER: (state, zones) => {
    state.zoneFilter = zones;
  },
  SET_EVENT_SPECIFIC_SEARCHABLE_FIELDS: (state, fields) => {
    state.eventSpecificSearchableFields = fields;
  },
  SET_DATE_FILTER: (state, date) => {
    state.dateFilter = date;
  },
  SET_STARTTIME_FILTER: (state, time) => {
    state.startTimeFilter = time;
  },
  SET_ENDTIME_FILTER: (state, time) => {
    state.endTimeFilter = time;
  },
  SET_EVENT_FILTER: (state, events) => {
    state.eventFilter = events;
  },
  RESET_STATE: (state) => {
    const newState = _.cloneDeep(initialState);
    newState.eventTypes = state.eventTypes;
    Object.assign(state, newState);
  },
  SET_TIME_SET: (state, bool) => {
    state.timeSet = bool;
  },
  SET_SIDEBAR_FORM_STATE: (state, value) => {
    state.sidebarForm = value?.formItems || null;
    state.sidebarStateSignature = value?.stateSignature || null;
  },
};

const actions = {
  setEventTypes({ commit }, types) {
    commit("SET_EVENT_TYPES", types);
  },
  setCameraFilter({ commit }, cameraId) {
    commit("SET_CAMERA_FILTER", cameraId);
  },
  setZoneFilter({ commit }, zoneId) {
    commit("SET_ZONE_FILTER", zoneId);
  },
  setEventSpecificSearchableFields({ commit }, fields) {
    commit("SET_EVENT_SPECIFIC_SEARCHABLE_FIELDS", fields);
  },
  setDateFilter({ commit }, date) {
    commit("SET_DATE_FILTER", date);
  },
  setStartTimeFilter({ commit }, time) {
    commit("SET_STARTTIME_FILTER", time);
  },
  setEndTimeFilter({ commit }, time) {
    commit("SET_ENDTIME_FILTER", time);
  },
  setEventFilter({ commit }, event) {
    commit("SET_EVENT_FILTER", event);
  },
  resetToInitialState({ commit }) {
    commit("RESET_STATE");
  },
  setTimeSet({ commit }, bool) {
    commit("SET_TIME_SET", bool);
  },
  setSidebarFormState({ commit }, value) {
    commit("SET_SIDEBAR_FORM_STATE", value);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
