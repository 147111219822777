import { t } from "@eencloud/core-components/src/service/locale";

export function getTranslatedEventType(event, eventTypes) {
  const eventType = eventTypes[event.type];
  if (
    event.category &&
    eventType.categoriesTranslated &&
    eventType.categoriesTranslated[event.category]
  ) {
    return eventType.categoriesTranslated[event.category];
  } else {
    return eventType ? eventType.eventTypeTranslated : t(event.type);
  }
}

export function formatFilters(filters) {
  return filters.flatMap((filter) => {
    if (filter.indexOf("Analytics") !== -1) {
      switch (filter) {
        case "AnalyticsObject":
          return [
            "objectDetected",
            "objectInAreaDetected",
            "objectTripwireDetected",
          ];
        case "AnalyticsPerson":
          return [
            "personDetected",
            "personInAreaDetected",
            "personTripwireDetected",
          ];
      }
    } else {
      return filter;
    }
  });
}